*, *::before, *::after{
  font-family: 'Heebo', sans-serif;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  position: fixed;
  width: 100%;
  overflow: hidden;
  /* min-height: 100%;
  max-height: 100%; */
  /* background-color: #000000; */
  
  font-family: 'Heebo', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@media (max-width: 850px) {
  body, html {
    height: auto;
    overflow: auto;
  }
}
.style_TopApp__1rIBH {
  /* border: 1px solid red; */
  margin: 0;
  padding: 0;
  /* height: 100%; */
  height: calc(var(--vh, 1vh) * 100);
  max-width: 100%;
  background-color: rgb(222, 222, 224);
  /* height: 100%; */
  background-size: 100%;
  background-position: center;
  position: relative;
  overflow: hidden;
}
  
  /* font-family: sans-serif;
  background-size: 100%;
  background-position: center;
  background-color: #0000;
  min-height: 100%;
  min-width: 100%; 
*/
  
  .style_TopApp__1rIBH div.style_Whatsapp__1vLW_{
    position: fixed;
    left: 15px;
    bottom: 80px;
    z-index: 1;
    

  }
  
  .style_TopApp__1rIBH div.style_Whatsapp__1vLW_ img{
        opacity: .9;
    }
  
  .style_TopApp__1rIBH .style_App__2u5mf {
    margin: 0;
    padding: 0;
    display: flex;
    height: calc(var(--vh, 1vh) * 100);
    
    flex: 1 1;
    flex-direction: column;
    justify-content: start;
  }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp {
      display: flex;
      flex-direction: row-reverse;
      align-content: center;
      background:transparent;
      padding: 5px;
      padding-left: 15px;
      flex: 0 0 100px;
    }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_GoToStoresBtn__2pNL6{
        /* border: 1px solid red; */
        max-height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_GoToStoresBtn__2pNL6 div.style_StoresImg__hwj4F{
          margin-right: 10px;
          margin-left: 10px;
          border: 2px solid var(--mainWhiteOrBlack);
          border-radius: 25px;
          height: 52px;
          width: 52px; 
          cursor: pointer;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_GoToStoresBtn__2pNL6 div.style_StoresImg__hwj4F img{
            width: 50px;
            height: 50px;
          }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_GoToStoresBtn__2pNL6 div.style_StoresImg__hwj4F img:hover{
            cursor: pointer;
          }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_GoToStoresBtn__2pNL6 div.style_StoresImg__hwj4F .style_StoresImgIcon__k9_gu {
            cursor: pointer;
            font-size: 34px;
            color: var(--mainWhiteOrBlack);
            margin: 8px;
          }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk {
        /* border: 1px solid red; */
        justify-self: end;
        max-height: 130px;
        display: flex;
        align-items: center;
      }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_CallToAction__TwqzK {
          direction: ltr;
          margin: 0 20px;
          background: #fff;
          color: #000;
          padding: 10px 15px;
          border-radius: 22px;
          text-decoration: none;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_SocialIcons__2gKhm{
          margin-left: 15px;
          display: flex;
          justify-content: space-around;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_SocialIcons__2gKhm > *{
          cursor: pointer;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_SocialIcons__2gKhm > * + *{
          margin-right: 5px;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_DivLogin__1TkDr {
          display: flex;
          flex: 1 1;
          /* align-self: center; */
          flex-flow: row-reverse wrap;
          text-align: center;
          justify-content: center;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_DivLogin__1TkDr .style_LogIconDiv__3VbpT {
            border: 2px solid var(--mainWhiteOrBlack);
            border-radius: 25px;
            height: 52px;
            width: 52px; 
            cursor: pointer;
          }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_DivLogin__1TkDr .style_LogIconDiv__3VbpT .style_LogIconDivIcon__1tB5a {
              cursor: pointer;
              font-size: 34px;
              color: var(--mainWhiteOrBlack);
              margin: 8px;
            }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_LoginText__3XAsF {
          align-content: center;
          margin: 14px 14px 14px 10px;
          color: var(--mainWhiteOrBlack);
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp .style_AppHeader__Login__32kAk .style_LoginTextOut__Bf0fU {
          align-content: center;
          margin: 0px 14px 0px 10px;
          color: var(--mainWhiteOrBlack);
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ{
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top : 20px;
        border: 1px solid var(--mainWhiteOrBlack);
        border-radius: 50px;
        width: 90%;
        height: 50%;
        color: var(--mainWhiteOrBlack);
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 2px;
        padding-bottom: 2px;

      }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ div.style_CartInfoTitle__2mr2y{
          /* border: 1px solid red; */
          color: var(--mainWhiteOrBlack);
          width: min-content;
          font-size: 10px;
          font-weight: 0;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ div.style_CartSum__2TNce{
          font-size: 20px;
          font-weight: 400;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ div.style_CartWrapper__Lhnjv{
          position: relative;
          width: 40px;
          display: flex;
          justify-content: flex-end;
        }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ div.style_CartWrapper__Lhnjv span.style_ItemsInCart__OLBcd{
            /* border: 1px solid red; */
            position: absolute;

            left: 40%;
            top: 18%;
            width: 15px;
            height: 15px;
            
            text-align: center;
            border-radius: 50%;
            background-color: var(--mainMainOrBlack);
            color: var(--mainWhite);
          }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ div.style_CartWrapper__Lhnjv span.style_ItemsInCart__OLBcd span{
              display: flex;
              justify-content: center;
              margin-top: 1px;
              font-size: 10px;
            }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ div.style_CartWrapper__Lhnjv img{
            width: 25px;
            height: 25px;
          }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppHeader__2_TQp div.style_CartInfo__2LwvJ div.style_CartWrapper__Lhnjv .style_CartIcon__19td9 {
            font-size: 25px;
            color: var(--mainMainOrBlack);
          }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppBody__2A5Lc {
      /* flex: 1 1; */
      /* max-height: calc(100% - 200px); */
      /* height: 100%; */
      z-index: 1;
    }
  
  .style_TopApp__1rIBH .style_App__2u5mf .style_AppFooter__1ruSQ {
      /* border: 3px solid red; */
      position: fixed;
      bottom: 0;
      /* left: 0; */
      /* right: 0; */
      height: 6.3%;
      width: 100%;
      overflow: hidden;
      background: transparent;
      z-index: 0;
      /* display: flex;
      justify-content: center;
      align-items: center; */
/* 
      .AppFooterA {
        height: 55px;
      } */
    }

.style_LoginHere__1LlOP{
  align-self:flex-end;
}
.style_AppLogo__2pvuM {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  max-width: 80px !important;
  /* max-height: 100px !important; */
  height: 80px;
  margin: 5px;
  justify-self:center;
  margin-right: 10px;
  margin-left: 10px;
  overflow: hidden;
}
/* pointer-events: none; */
.style_AppLogo__2pvuM img {
    height: 80px;
    position: relative;
    max-height: initial;
    max-width: initial;
  }


.style_AppTopMenu__2j_O6 {
  text-align: center;
  display: flex;
  flex-flow: row-reverse wrap;
  justify-content: flex-end;
  align-content: center;
  padding: 5px;
  flex: 1 1;
}
.style_AppTopMenu__2j_O6.style_button__Bj4Tv {
  background-color: transparent;
  color: var(--mainWhite);
}



.style_bg_second__1h95O {
  background-color: var(--thinScrBg);
}

.style_BigappsLogo__3jCq9{
  max-width: 100px;
  height: 30px;
}

/* @media (max-width: 850px){
  div.TopApp{
    div.App{
        div.AppHeader{
          div.AppHeader__Login {
            div.DivLogin{
              div.LogIconDiv{
              }
            }
          }
        }
    }
  }
} */


/* @media screen and (max-width: 600px) {
    .AppTopMenu {
      display: flex;
      flex-flow: column-reverse wrap;
    }
    .AppHeader {
      color:black;
      display: flex;
      flex-direction: column;
      align-content: center;
    }
    .AppLogo {
      display: none;
    }
    .AppBody{
      margin-bottom: 50px;
      display: flex;
      flex-flow: column-reverse;
      justify-content: space-around;
    }
  } */


  @media (max-height: 300px) {
      div.style_TopApp__1rIBH div.style_Whatsapp__1vLW_{
        width: 0;
      }
  }
.style_AppTopMenu__1s_ks {
  text-align: center;
  display: flex;
  flex-flow: row-reverse wrap;
  justify-content: flex-end;
  align-content: center;
  padding: 5px;
  flex: 1 1;
}

/* .Container{
  align-self: center;
}


div.menubtn {
  padding: 10px;
  background-color: transparent;
  border: 0px;
  color: var(--mainWhite);
  font-size: 24px;
  font-weight: 400;
  cursor: pointer;

  &.menubtnActive {
    text-decoration: underline var(--mainWhite);
  }
}
input.searchbox {
    background: no-repeat right;
    background-size: 20px;
    width: 200px;
    cursor: pointer;
    border: 1px solid var(--mainWhite);
    border-radius: 31px;
    color: black;
} */

/* @media screen and (max-width: 600px) {
    .AppTopMenu {
      display: flex;
      flex-flow: column-reverse wrap;
    }
  } */
.style_SocialIcons__25pCt{
    margin-top: 8px;
    display: flex;
    justify-content: center;
}

.style_MobileBtns__1nwoe {
    margin-top: 20px !important;
    margin-right: auto;
    margin-left: auto;
    width: 75%;
    //margin: auto;
}

.style_burgerIcon__1TsrO {
    font-size: 38px;
    color: var(--mainWhiteOrBlack);
    border-radius: 20px;
    border: 4px solid var(--mainWhiteOrBlack);
}

div.style_StoresImg__3r_gh{
    margin-right: 10px;
    margin-left: 10px;
    border: 2px solid var(--mainWhite);
    border-radius: 25px;
    height: 42px;
    width: 42px; 
    cursor: pointer;
  }

div.style_StoresImg__3r_gh img{
      width: 50px;
      height: 50px;
    }

div.style_StoresImg__3r_gh img:hover{
      cursor: pointer;
    }

div.style_StoresImg__3r_gh .style_StoresImgIcon__2IuaT {
      cursor: pointer;
      font-size: 25px;
      color: var(--mainWhite);
      margin: 7px;
    }

  .style_LogIconDiv__2uVJB {
    border: 2px solid var(--mainWhite);
    border-radius: 25px;
    height: 42px;
    width: 42px;
  }

  .style_LogIconDiv__2uVJB .style_LogIconDivIcon__YODE7 {
      font-size: 25px;
      color: var(--mainWhite);
      margin: 7px;
    }

div.style_Container__6W-ru{
    align-self: center;
}

div.style_Container__6W-ru div.style_SidebarContent__2vWcI {
        min-height: 100%;
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

div.style_Container__6W-ru div.style_AppTopMenu__1DmL2 {
        text-align: center;
        display: flex;
        flex-flow: row-reverse wrap;
        justify-content: space-around;
        margin: 5px;

        display: flex;
        justify-content: center;
        align-items: center;

        /* div.SearchContainer{
            border: 2px solid blue;
            margin-right: 2vw;
        } */
        
    }

div.style_Container__6W-ru div.style_menubtn__36WYm {
        padding: 10px;
        background-color: transparent;
        border: 0px;
        color: var(--mainWhiteOrBlack);
        font-size: 24px;
        font-weight: 200;
        cursor: pointer;
    }

div.style_Container__6W-ru div.style_menubtn__36WYm.style_menubtnActive__23guY {
            font-weight: 400;
            -webkit-text-decoration: underline var(--mainWhiteOrBlack);
                    text-decoration: underline var(--mainWhiteOrBlack);
        }

div.style_Container__6W-ru div.style_menubtn__36WYm a {
            color: inherit;
            text-decoration: inherit;
        }

div.style_Container__6W-ru input.style_searchbox__17oQG {
        background: no-repeat right;
        background-size: 20px;
        width: 200px;
        cursor: pointer;
        border: 1px solid var(--mainWhite);
        border-radius: 31px;
        color: black;
    }

div.style_Container__6W-ru div.style_BigappsLogo__2RIyD{
        /* border: 1px solid red; */
        width: 50%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        /* margin: auto; */
        /* margin-top: auto; */
        cursor: pointer;
        /* margin-top: 10%; */
        opacity: .7;
    }

div.style_Container__6W-ru div.style_mobileMenubtn__2CfuY {
        border-bottom: 2px solid #808080;
        padding: 10px 0 10px 0;
        font-weight: 500;
        font-size: 20px;
        color: #FFF;
    }

div.style_Container__6W-ru div.style_mobileMenubtn__2CfuY a {
            color: inherit;
            text-decoration: inherit;
        }

div.style_Container__6W-ru div.style_MobVersionDiv__3O25G {
        color: var(--mainWhite);
        font-size: 1rem;
        margin-bottom: 15px;
    }
div.style_Container__3jc5z{
    margin-right: 10px;
    color: var(--mainWhiteOrBlack);
}
    div.style_Container__3jc5z div.style_InputField__3gDTz{
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        border: 2px solid var(--mainWhiteOrBlack);
        border-radius: 20px;
        padding: 6px;
        
        height: 30px; /*ADDED!*/
        overflow: hidden; /*ADDED!*/

        transition: width .6s;
    }
    div.style_Container__3jc5z div.style_InputField__3gDTz .style_InputFieldIcon__1va9k {
          font-size: 25px;
          color: var(--mainWhiteOrBlack);
        }
    div.style_Container__3jc5z div.style_InputField__3gDTz input{
          color: var(--mainWhiteOrBlack);
        }
    div.style_Container__3jc5z ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--mainWhiteOrBlack);
        opacity: 1; /* Firefox */
      }
    div.style_Container__3jc5z :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: var(--mainWhiteOrBlack);
      }
    div.style_Container__3jc5z ::-ms-input-placeholder { /* Microsoft Edge */
        color: var(--mainWhiteOrBlack);
      }
div.style_container__2CilB {
  align-self: center;
  height: calc((var(--vh, 1vh) * 100) - 100px - var(--AppFooter) );
  width: 100%;
  z-index: 1;
}

  div.style_container__2CilB div.style_AppBody__7B4B3 {
    height: calc((var(--vh, 1vh) * 100) - 100px - var(--AppFooter) );
  }

@media screen and (max-width: 600px) {
  div.style_container__2CilB {
    height: calc((var(--vh, 1vh) * 100) - 100px );
  }
    div.style_container__2CilB div.style_AppBody__7B4B3 {
      width: 100vw;
      height: calc((var(--vh, 1vh) * 100) - 100px );
      /* display: flex;
      flex-flow: column-reverse wrap; */
    }
}

/* @media screen and (min-width: 601px) { */
  div.style_Container__1Wvk8{
    /* border: 2px solid red; */
    justify-self: right;
    flex: 1 1;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    padding: 5px 5px 0 0;
    white-space: nowrap;
    border-radius: var(--brdRadius);
    background-color: var(--mainWhite);
    /* overflow: hidden; */
    overflow: none;
    height: 100%;
    min-width: 34vw;

  }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o {
      /* border: 4px solid green; */
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      align-content: center;
      padding: 2px;
      height: 100%;
    }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 {
        display: flex;

        /* flex-flow: row-reverse wrap; */
        flex-flow: row-reverse;
        align-self: center;
        align-content: center;
        justify-content: space-between;
        border-radius: 50px;
        height: 50px;
        width: 340px;
        max-width: 100%;
        border-width: 1px;
        border-style: solid;
        border-color: var(--mainMainOrBlack);
      }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 .style_CartSum__2a95R {
          display: flex;
          align-items: center;
          height: 100%;
          color: var(--mainMainOrBlack);
        }
  /* padding-top: 6px; */
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 .style_CartSum__2a95R .style_CartSumSh__JtfpG {
            /* position: relative; */
            /* bottom: -.4vh; */
            font-size: 16px;
          }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 .style_CartSum__2a95R .style_CartSumVal___dFh6 {
            font-size: 26px;
          }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 .style_CartText__15dvp {
          align-self: center;
          text-align: right;
          margin-right: 15px;
          font-size: 16px;
          line-height: 18px;
          color: var(--mainMainOrBlack);
        }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 .style_CartWrapper__1YetC {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;  
        }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 .style_CartWrapper__1YetC .style_CartIcon__35RcN {
            font-size: 41px;
            color: var(--mainMainOrBlack);
            margin: 3px;
          }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_Summary__3u2O7 .style_CartWrapper__1YetC .style_ItemsInCart__3uZa0 {
            color: var(--mainWhite);
            position: relative;
            height: 21px;
            line-height: 21px;
            min-width: 21px;
            /* top: -18%; */
            border-radius: var(--brdRadius);
            padding-left: 3px;
            padding-right: 3px;
            font-size: 14px;
            font-weight: 600;
            background-color: var(--mainMainOrBlack);
          }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_CartItems__1eCle {
        /* border: 2px solid red; */
        margin-top: 20px;
        margin-bottom: 20px;
        overflow: auto;
        max-height: 100%;
        padding: 0 20px 0 20px;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
      }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_CartItems__1eCle .style_CartItemsEmpty__1oUws {
          margin-top: 130px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          /* gap: 6px; */
          color: var(--mainMainOrBlack);
          top: 15vh;
        }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_CartItems__1eCle .style_CartItemsEmpty__1oUws span{
            white-space: wrap;
          }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_CartItems__1eCle .style_CartItemsEmpty__1oUws span:nth-child(1) {
            margin-bottom: 6px;
            font-size: 27px;
            font-weight: 600;
          }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_CartItems__1eCle .style_CartItemsEmpty__1oUws span:nth-child(2) {
            margin-bottom: 6px;
            font-size: 25px;
          }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_CartItems__1eCle .style_CartItemsEmpty__1oUws :nth-child(3) {
            margin-bottom: 6px;
            font-size: 100px;
            color: var(--mainColor);
          }
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_StepsBar__vrE3h{
        /* border: 1px solid red; */
        margin: auto;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        /* font-family: Consolas; */
        font-weight: 400;
        color: #dcdcdc;
      }
  /* overflow-x: auto; */
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_StepsBar__vrE3h div.style_StepBar__2lRWM{
            /* color: #dcdcdc; */
            width: 1.2rem;
            height: 1.2rem;
            align: center;
            /* border: 1px solid #dcdcdc; */
            border-radius: var(--brdRadius);
            //cursor: pointer;
            
        }
  /* overflow-y: hidden; */
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_StepsBar__vrE3h .style_Active__AMqff {
          color: #5c5c5c;
          cursor: pointer;
          border: 1px solid #dcdcdc;
        }
  /* word-wrap: break-word; */
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_StepsBar__vrE3h .style_Inactive__7yJr4 {
          cursor: default;
        }
  /* width: max-content; */
  div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_StepsBar__vrE3h .style_Current__stLHJ {
          color: #5c5c5c;
          border: 1px solid #5c5c5c;
        }



@media (max-width: 850px){
  div.style_Container__1Wvk8{
    margin: 3px;
    padding: 2px 2px 0 0;
    /* margin: 5px 5px 0 5px;
    padding: 5px 5px 0 5px; */
  }
}


@media (max-width: 600px){
      div.style_Container__1Wvk8 div.style_TopDiv__l052o div.style_CartItems__1eCle {
        padding: 0;
      }
}
div.style_Container__3l8I7{
    /* border: 2px solid red; */
    display: flex;
    border-bottom: 2px solid #D3D3D3;
    margin-top: 10px;
    justify-content: flex-center;
    z-index: 1;
}

    div.style_Container__3l8I7 div.style_RightElements__2BG9Q{
        /* border: 5px solid blue; */
        z-index: 1;
    }

    /* width: max-content; */

    div.style_Container__3l8I7 div.style_RightElements__2BG9Q div.style_ItemCheckbox__1rzXe{
        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B{
        /* border: 5px solid red; */
        /* z-index: 1; */
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 5px;
        margin-right: 5px;
    }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY {
            display: flex;
        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW {
                flex: 2 1;
            }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R{
                    /* border: 1px solid blue; */
                    overflow: hidden;
                    display: flex;
                    align-items: flex-center;
                }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48{
                        /* border: 1px solid red; */
                        padding: 0;
                        margin: 0;
                        height: 100%;
                        max-width: 200px;
                        min-width: 100px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48 .style_ItemImage__NDe1P{
                            /* border: 1px solid red; */
                            max-width:  50px;
                        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48 div.style_ItemFlags__SpN14 {
                            display: flex;
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48 div.style_ItemFlags__SpN14 .style_label__3Vspw{
                                cursor: help;
                                /* background-color: #fff; */
                                /* color: #000; */
                                border-radius: 10px;
                                width: 18px;
                                text-align: center;
                                line-height: 18px;
                                font-size: 15px;
                                position: absolute;
                                left: 5px;
                                top: calc(50% - 9px);;
                            }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48 div.style_ItemFlags__SpN14 div.style_iFlags__CcvwG {
                                position: relative;
                                color: var(--mainWhite);
                                width: 100%;
                                max-width: 200px;
                                border-radius: 30px;
                                height: 20px;
                                font-size: 12px;
                                font-weight: 500;
                                background-color: #E10000;
                                
                                /* &.iNew {
                                    border:1px solid  #FF8000;
                                    background-color: #FF8000;
                                }
                                
                                &.iSale {
                                    border:1px solid  #E10000;
                                    background-color: #E10000;
                                }
                                
                                &.iSeason {
                                    border:1px solid  #FF8000;
                                    background-color: #FF8000;
                                } */
                            }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R div.style_ItemDetails__2tagh{
                        /* border: 1px solid red; */
                        height: 100%;
                        width: 100%;
                        min-width: 70px;
                        padding: 0;
                        margin: 0;
                        margin-right: 10px;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        overflow: hidden;
                        overflow-x: auto;
                        line-height: 120%;
                    }

    /* white-space: break-spaces; */

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R div.style_ItemDetails__2tagh div.style_ItemName__1C43R{
                            /* border: 1px solid red; */
                            font-size: 18px;
                            font-weight: 500;
                            /* overflow: hidden; */
                            /* text-overflow: ellipsis; */
                            white-space: normal;  
                            cursor: default;
                            margin:0;
                            color: var(--mainMainOrBlack);
                        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R div.style_ItemDetails__2tagh div.style_ItemPrice__vRLsE{
                            display: flex;
                            flex-wrap: wrap;
                            grid-gap: 5%;
                            gap: 5%;
                            justify-content: flex-start;
                            font-size: 18px;
                            font-weight: 500;
                            font-family: 'Heebo', sans-serif;
                            cursor: default;

                        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R div.style_ItemDetails__2tagh div.style_ItemQuantity__1uWEj{
                            /* border: 1px solid red; */
                            font-size: 18px;
                            font-weight: 500;
                            cursor: default;
                        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidLeftElements__3HCIM {
                flex: 1 1;
                z-index: 1;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: 100%;
                max-width: 120px;
                justify-content: space-between;
                height: max-content;
            }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidLeftElements__3HCIM div.style_MoreInfo__1BEWk{
                    /* border: 1px solid red; */
                    margin-bottom: auto;
                    width: 100%;
                    /* direction : rtl; */
                    flex: 1 1;
                    padding-right: 5px;
                }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidLeftElements__3HCIM div.style_MoreInfo__1BEWk div.style_MoreInfoTooltip__3X6en{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidLeftElements__3HCIM div.style_MoreInfo__1BEWk div.style_MoreInfoTooltip__3X6en .style_Tooltip__iOdLT{
                            max-width: 300px !important;
                            text-align: start;
                            white-space: pre-wrap !important;
                        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidLeftElements__3HCIM div.style_MoreInfo__1BEWk div.style_MoreInfoTooltip__3X6en > .style_label__3Vspw{
                        cursor: help;
                        background-color: var(--mainMainOrBlack);
                        color: #fff;
                        border-radius: 10px;
                        display: inline-block;
                        width: 18px;
                        text-align: center;
                        line-height: 18px;
                        font-size: 12px;
                        margin-bottom: 10px;
                    }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidLeftElements__3HCIM div.style_Buttons__1MZ5Y{
                    /* border: 2px solid green; */
                    margin-bottom: auto;
                    flex: 1 1;
                    padding-right: 5px;
                }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidLeftElements__3HCIM div.style_Buttons__1MZ5Y button{
                        border-radius: 25px;
                        width: 100px;
                        height: 28px;
                        font-size: 14px;
                        /* font-weight: 500; */
                        color: var(--mainWhiteOrBlack);
                        font-family: 'Heebo', sans-serif;
                        background-color: #FFFFFF;
                        outline: none;
                        cursor: pointer;
                    }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidLeftElements__3HCIM div.style_Buttons__1MZ5Y div.style_EditButton__2Bas2{
                        margin-bottom: 5px;
                    }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidLeftElements__3HCIM div.style_Buttons__1MZ5Y div.style_EditButton__2Bas2 button {
                            background-color: var(--mainColor);
                            border: 1px solid var(--mainColor);
                        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidLeftElements__3HCIM div.style_Buttons__1MZ5Y div.style_RemoveButton__1j-Y8 button {
                            background-color: var(--mainColor);
                            border: 1px solid var(--mainColor);
                        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_Comment__33HGI{
            /* border: 1px solid blue; */
            display: flex;
            align-items: center;
            margin-top: 10px;
            width: 100%;
        }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_Comment__33HGI div.style_Title__16UQc{
                font-size: 13px;
                text-decoration: underline;
                cursor: pointer;
            }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_Comment__33HGI div.style_InputText__1Vjnh{
                width: 100%;
            }

    div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_Comment__33HGI div.style_InputText__1Vjnh input{
                    width: 100%;
                    font-size: 13px;
                    outline: none;
                    border: none;
                    font-family: 'Heebo', sans-serif;
                }

@media (max-width: 600px){

                            div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48 .style_ItemImage__NDe1P{
                                width:  60px;
                                cursor: pointer;
                            }
                                div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R div.style_ImageAndFlag__1WN48 div.style_ItemFlags__SpN14 div.style_iFlags__CcvwG{
                                    height: 18px;
                                }
                            div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R div.style_ItemDetails__2tagh div.style_ItemName__1C43R{
                                font-size: 14px;
                            }
                            div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R div.style_ItemDetails__2tagh div.style_ItemPrice__vRLsE{
                                font-size: 12px;
                            }

                            div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidRightElements__1QfHW div.style_ImagFlagDetalisWrapper__1D88R div.style_ItemDetails__2tagh div.style_ItemQuantity__1uWEj{
                                font-size: 12px;
                            }
                        div.style_Container__3l8I7 div.style_MidElements__2VI-B div.style_MidUpElements__EHHaY div.style_MidLeftElements__3HCIM div.style_Buttons__1MZ5Y button{
                            height: 24px;
                            width: 80px;
                        }
}

div.style_Container__3rwbv{
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    user-select: none;
    color: #cc0000;

    /* border: 3px solid red; */
    /* height: 100%;
    width: 100%; */
    position: relative;
}
    div.style_Container__3rwbv div.style_cancleBtn__1bCDd{
        margin-left: 2%;
        color: #FFf;
        font-size: 30px;
        cursor: pointer;
        text-align: left;
    }
    div.style_Container__3rwbv div.style_Slider__30wd7{
        /* border: 3px solid white; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 60vh;
    }
    div.style_Container__3rwbv div.style_Slider__30wd7 div.style_RightArrow__1Zb-Z{
            /* border: 2px solid red; */
            display: flex;
            justify-content: center;
            align-items:center;
            height: 100%;
            font-size: 50px;
            margin: auto;
            padding-right: 5%;
            padding-left: 2%;
            color: white;
            cursor: pointer;
        }
    div.style_Container__3rwbv div.style_Slider__30wd7 div.style_Images__2NpA2{
            /* border: 3px solid red; */
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            /* align-items:center; */
            overflow: auto;
        }
    div.style_Container__3rwbv div.style_Slider__30wd7 div.style_LeftArrow__3sND-{
            /* border: 1px solid red; */
            display: flex;
            justify-content: center;
            align-items:center;
            height: 100%;
            width: max-content;
            font-size: 50px;
            padding-left: 5%;
            padding-right: 2%;
            color: white;
            cursor: pointer;
            draggable: false;
        }
    div.style_Container__3rwbv div.style_Dots__2qE8a{
        /* border: 3px solid green; */
        margin-top: 3%;
        display: flex;
        justify-content: center;
        overflow: hidden;
    }
    div.style_Container__3rwbv div.style_Dots__2qE8a div.style_Dot__gmrbO{
            padding: 8px;
            border-radius: 100%;
        }
    div.style_Container__3rwbv div.style_Dots__2qE8a > * + * {
        margin-right: 2%;
    }

div.style_Container__3oDAb{
    /* border: 4px solid brown; */
    height: 100%;
    overflow: auto;
}
    
    div.style_Container__3oDAb div.style_OptionsDescription__3pDJ5{
        font-size: 14px;
        margin-bottom: 5px;
    }
    
    div.style_Container__3oDAb div.style_ItemOptionList__2yjj7{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* gap: 10px; */
        padding: 0 15% 0 15%;
    }
    
    div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo{
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            font-size: 20px;
        }
    
    div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo label, div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo input{
                margin-top: 1px;
                /* vertical-align: top; */
            }
    
    div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 > * + *{
        margin-top: 10px;
    }



@media (-webkit-max-device-pixel-ratio: 1.1458333333333333), (max-resolution: 110dpi){
        div.style_Container__3oDAb div.style_OptionsDescription__3pDJ5{
            font-size: 18px;
        }
            div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo{
                font-weight: 500;
                font-size: 20px;
            }
}

@media (-webkit-min-device-pixel-ratio: 1.3541666666666667), (min-resolution: 130dpi){
        div.style_Container__3oDAb div.style_OptionsDescription__3pDJ5{
            font-size: 12px;
        }
            div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo{
                font-weight: 500;
                font-size: 12px;
            }

                div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo input{
                    /* width: 10px; */
                }
}

@media (-webkit-min-device-pixel-ratio: 1.5625), (min-resolution: 150dpi){
        div.style_Container__3oDAb div.style_OptionsDescription__3pDJ5{
            font-size: 10px;
        }
            div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo{
                font-weight: 500;
                font-size: 10px;
            }
}

@media (max-width: 850px){
        div.style_Container__3oDAb div.style_OptionsDescription__3pDJ5{
            font-size: 18px;
        }
            div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo{
                font-weight: 500;
                font-size: 17px;
            }
                    div.style_Container__3oDAb div.style_ItemOptionList__2yjj7 div.style_ItemOption__3hBfo div.style_WrapBtnAndName__3tFdI input{
                        width: 20px;
                    }
}
div.style_Container__3ty64{
    background: #D6D6D6 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 5px;
    padding: 20px 0 20px 0;
    cursor: pointer;
}


@media (max-width: 500px){
    div.style_Container__3ty64{
        overflow: auto;
        font-size: 11px;
        margin-left: 5px;
        margin-right: 5px;
        padding: 10px 0 10px 0;
    }
}
/* @media screen and (min-width: 601px) { */

div.style_Container__1q1yQ{
    /* border: 4px solid brown; */
    /* position: relative; */
    height: 100%;
    overflow: auto;

}

/* overflow: clip; */

div.style_Container__1q1yQ div.style_ToppingsDescription__3EzDt{
        font-size: 14px;
        margin-bottom: 5px;
    }

/* width: 100%; */

div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3{
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* gap: 10px; */
        padding: 0 15%;
    }

div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            font-weight: 500;
            font-size: 20px;

        }

div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC div.style_WrapBtnAndName__1u6JR{
                flex-basis: 31%;
                flex-grow: 3;
                min-width: 1%;
                white-space: pre-wrap;                
            }

/* text-align: right; */

div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC div.style_WrapBtnAndName__1u6JR .style_ItemToppingName__Xmcqt{
                    min-width: 70%;
                    overflow-wrap: break-all;
                }

div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC .style_ItemToppingPrice__3Mn3S{
                flex: 5 1 1;
            }

div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 > * + *{
        margin-top: 10px;
    }

div.style_Container__1q1yQ div.style_btnContainer__17T9W{
        flex: 1 1;
        max-width: 80px;
        min-width: 70px;
    }

div.style_Container__1q1yQ div.style_btnContainer__17T9W div.style_InputWithCounter__3m_uN{
            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            align-content: flex-end;
            justify-content: center;
            border-radius: 25px;
            border-width: 0px;
            margin-right: 5px;
            height: 30px;
            position: relative;
            background-color: var(--mainColor);
            
        }

div.style_Container__1q1yQ div.style_btnContainer__17T9W div.style_InputWithCounter__3m_uN div.style_buttonPlus__2OIUt{
                position: absolute;
                margin-top: 3px;
                margin-right: 5px;
                right: 3px;
                color: white;
                line-height: 25px;
                font-size: 33px;
                font-weight: 300;
                color: var(--mainWhiteOrBlack);
            }

div.style_Container__1q1yQ div.style_btnContainer__17T9W div.style_InputWithCounter__3m_uN div.style_ItemAmount__1xopw{
                /* width: 25px; */
                margin-top: 3px;
                align: center;
                color: white;
                line-height: 25px;
                font-size: 20px;
                font-weight: 400;
                color: var(--mainWhiteOrBlack);
            }

div.style_Container__1q1yQ div.style_btnContainer__17T9W div.style_InputWithCounter__3m_uN div.style_buttonMinus__26yYR{
                position: absolute;
                margin-top: 3px;
                margin-left: 5px;
                left: 3px;
                color: white;
                line-height: 25px;
                font-size: 53px;
                font-weight: 300;
                color: var(--mainWhiteOrBlack);
            }

div.style_Container__1q1yQ div.style_btnContainer__17T9W div.style_InputWithCounter__3m_uN div.style_buttonMinus__26yYR:hover{cursor: pointer;}

div.style_Container__1q1yQ div.style_btnContainer__17T9W div.style_InputWithCounter__3m_uN div.style_buttonPlus__2OIUt:hover{cursor: pointer;}

div.style_Container__1q1yQ div.style_RightSideTabs__2dg94{
        display: flex;
        flex-direction: column;
        direction: ltr;
        position: absolute;
        right: 0;
        top: 50px;
        max-height: 100%;
        overflow: auto;
        font-size: 12px;
        width: 80px;
        z-index: 20;
    }

div.style_Container__1q1yQ div.style_RightSideTabs__2dg94 div.style_SelectedToppings__3EZpH {
            display: flex;
            flex-direction: column;
        }

div.style_Container__1q1yQ div.style_RightSideTabs__2dg94 div.style_SelectedToppings__3EZpH div.style_ToppingToEdit__w5feL{
                color: var(--mainWhiteOrBlack);
                display: flex;
                border-radius: 20px 0 0 20px;
                justify-content: space-between;
                align-items: center;
                cursor: default;
                opacity: .7;
                background-color: var(--mainColor);
            }

div.style_Container__1q1yQ div.style_RightSideTabs__2dg94 div.style_SelectedToppings__3EZpH div.style_ToppingToEdit__w5feL div.style_ToppingNameAndPrice__1cyLM{
                    text-align: right;  
                }

div.style_Container__1q1yQ div.style_RightSideTabs__2dg94 div.style_SelectedToppings__3EZpH div.style_ToppingToEdit__w5feL div.style_ToppingNameAndPrice__1cyLM div.style_ToppingName__3ElFO{
                        width: 60px;
                        overflow: hidden !important;
                        text-overflow: ellipsis !important;
                    }

div.style_Container__1q1yQ div.style_RightSideTabs__2dg94 div.style_SelectedToppings__3EZpH div.style_ToppingToEdit__w5feL div.style_ToppingNameAndPrice__1cyLM div.style_ToppingName__3ElFO span.style_ToppingXRemover__ZYCRL{
                            padding-left: 3px;
                            cursor: pointer;
                        }

div.style_Container__1q1yQ div.style_RightSideTabs__2dg94 div.style_SelectedToppings__3EZpH div.style_ToppingToEdit__w5feL div.style_ToppingNameAndPrice__1cyLM div.style_ToppingName__3ElFO span.style_ToppingXRemover__ZYCRL:hover{}

div.style_Container__1q1yQ div.style_RightSideTabs__2dg94 div.style_SelectedToppings__3EZpH div.style_ToppingToEdit__w5feL div.style_ToppingNameAndPrice__1cyLM div.style_ToppingPrice__37kI1{
                        padding-right: 13px;
                    }

div.style_Container__1q1yQ div.style_RightSideTabs__2dg94 div.style_SelectedToppings__3EZpH div.style_ToppingToEdit__w5feL:hover{}

div.style_Container__1q1yQ div.style_RightSideTabs__2dg94 div.style_SelectedToppings__3EZpH > * + * {
            margin-top: 5px;
        }

@media (-webkit-max-device-pixel-ratio: 1.1458333333333333), (max-resolution: 110dpi){
        div.style_Container__1q1yQ div.style_ToppingsDescription__3EzDt{
            font-size: 18px;
        }
            div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC{
                font-weight: 500;
                font-size: 20px;
            }
}

@media (-webkit-min-device-pixel-ratio: 1.3541666666666667), (min-resolution: 130dpi){
        div.style_Container__1q1yQ div.style_ToppingsDescription__3EzDt{
            font-size: 12px;
        }
            div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC{
                font-weight: 500;
                font-size: 12px;
            }

                div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC input{
                    width: 10px;
                }
}

@media (-webkit-min-device-pixel-ratio: 1.5625), (min-resolution: 150dpi){
        div.style_Container__1q1yQ div.style_ToppingsDescription__3EzDt{
            font-size: 10px;
        }
            div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC{
                flex-wrap: nowrap;
                font-weight: 500;
                font-size: 10px;
            }
}
/* } */

@media (max-width: 850px){
        div.style_Container__1q1yQ div.style_ToppingsDescription__3EzDt{
            font-size: 18px;
        }
        
        div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3{
            padding: 0 3%;
        }
                    div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC div.style_WrapBtnAndName__1u6JR input{
                        width: 20px;
                    }
                    div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC div.style_WrapBtnAndName__1u6JR .style_ItemToppingName__Xmcqt{
                        font-size: 17px;
                    }
                div.style_Container__1q1yQ div.style_ItemToppingList__3qDC3 div.style_ItemTopping__is0KC .style_ItemToppingPrice__3Mn3S{
                    font-size: 17px;
                    white-space: wrap;
                }
        div.style_Container__1q1yQ div.style_btnContainer__17T9W{
            max-width: 120px;
            min-width: 80px;
        }
            div.style_Container__1q1yQ div.style_btnContainer__17T9W div.style_InputWithCounter__3m_uN{
                margin-right: 5px;
                bottom: 5px;
                height: 40px;
            }
                div.style_Container__1q1yQ div.style_btnContainer__17T9W div.style_InputWithCounter__3m_uN div.style_buttonPlus__2OIUt{
                    right: 4px;
                    line-height: 35px;
                    /* font-size: 33px; */
                }
                div.style_Container__1q1yQ div.style_btnContainer__17T9W div.style_InputWithCounter__3m_uN div.style_ItemAmount__1xopw{
                    /* width: 25px; */
                    line-height: 35px;
                    /* font-size: 20px; */
                }
                div.style_Container__1q1yQ div.style_btnContainer__17T9W div.style_InputWithCounter__3m_uN div.style_buttonMinus__26yYR{
                    left: 4px;
                    line-height: 35px;
                    /* font-size: 53px; */
                }
}
/* @media screen and (min-width: 601px) { */
/* @media (-webkit-max-device-pixel-ratio: 1) { */

div.style_Container__3q394{
    /* border: 4px solid brown; */
    position: relative;
    height: 100%;
    overflow: auto;
}

div.style_Container__3q394 div.style_ToppingsDescription__2MbU0{
        font-size: 14px;
        margin-bottom: 5px;
        text-wrap-mode: wrap;
    }

div.style_Container__3q394 div.style_MaxToppingsError__dtly0{
        font-size: 12px;
        color: #FF0000;
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        top: -50px;
    }

div.style_Container__3q394 div.style_PizzaContainer__NH-II{
        /* border: 2px solid green; */
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
    }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU{
            display: flex;
            flex-direction: column;
            direction: ltr;
            position: absolute;
            right: 0;
            top: 0;
            max-height: 100%;
            overflow: auto;
            font-size: 12px;
            width: 110px;
            z-index: 20;
        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedProductOption__2JHTz{
                text-align: right;
                color: var(--mainWhiteOrBlack);
                border-radius: 20px 0 0 20px;
                padding-left: 13px;
                padding-right: 13px;
                background-color: var(--mainColor);
                opacity: 0.9;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I{
                display: flex;
                flex-direction: column;

                
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingToEdit__1dvWG{
                    color: var(--mainWhiteOrBlack);
                    display: flex;
                    border-radius: 20px 0 0 20px;
                    justify-content: space-between;
                    align-items: center;
                    cursor: default;
                    background-color: var(--mainColor);
                }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingToEditOp__3WB3S{
                    color: var(--mainWhiteOrBlack);
                    display: flex;
                    border-radius: 20px 0 0 20px;
                    justify-content: space-between;
                    align-items: center;
                    cursor: default;
                    background-color: var(--mainColor065);
                }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingNameAndPrice__30kIB{
                    text-align: right;
                }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingNameAndPrice__30kIB div.style_ToppingName__16znU{
                        width: 60px;
                        overflow: hidden !important;
                        text-overflow: ellipsis !important;
                    }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingNameAndPrice__30kIB div.style_ToppingName__16znU span.style_ToppingXRemover__1lD8t{
                            padding-left: 3px;
                            cursor: pointer;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingNameAndPrice__30kIB div.style_ToppingName__16znU span.style_ToppingXRemover__1lD8t:hover{
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingNameAndPrice__30kIB div.style_ToppingPrice__2F0LM{
                        padding-right: 13px;
                    }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops4__3ryRg, div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops2__c8b1F {
                        margin-right: 10px;
                        /*background-color: white;
                        border-radius: 25px;*/
                        position: relative;
                    }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops4__3ryRg {
                        height: 25px;
                        width: 25px;
                    }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops4__3ryRg div {
                            height: 12px;
                            width: 12px;
                            border: 1px solid white;
                            position: absolute;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops4__3ryRg div.style_TopLeftEmpty__1c6ZQ {
                            border-radius: 12px 0 0 0;
                            left: 0;
                            top: 0;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops4__3ryRg div.style_TopLeftFull__1CxwU {
                            border-radius: 12px 0 0 0;
                            left: 0;
                            top: 0;
                            background-color: white;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops4__3ryRg div.style_TopRightEmpty__16rD5 {
                            border-radius: 0 12px 0 0;
                            right: 0;
                            top: 0;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops4__3ryRg div.style_TopRightFull__2kcYW {
                            border-radius: 0 12px 0 0;
                            right: 0;
                            top: 0;
                            background-color: white;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops4__3ryRg div.style_BottomRightEmpty__1iqzj {
                            border-radius: 0 0 12px 0;
                            right: 0;
                            bottom: 0;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops4__3ryRg div.style_BottomRightFull__1gpOZ {
                            border-radius: 0 0 12px 0;
                            right: 0;
                            bottom: 0;
                            background-color: white;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops4__3ryRg div.style_BottomLeftEmpty__1Wjh5 {
                            border-radius: 0 0 0 12px;
                            left: 0;
                            bottom: 0;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops4__3ryRg div.style_BottomLeftFull__3-K6u {
                            border-radius: 0 0 0 12px;
                            left: 0;
                            bottom: 0;
                            background-color: white;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops2__c8b1F {
                        height: 24px;
                        width: 25px;
                    }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops2__c8b1F div {
                            height: 24px;
                            width: 12px;
                            border: 1px solid white;
                            position: absolute;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops2__c8b1F div.style_TopLeftEmpty__1c6ZQ {
                            border-radius: 12px 0 0 12px;
                            left: 0;
                            top: 0;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops2__c8b1F div.style_TopLeftFull__1CxwU {
                            border-radius: 12px 0 0 12px;
                            left: 0;
                            top: 0;
                            background-color: white;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops2__c8b1F div.style_TopRightEmpty__16rD5 {
                            border-radius: 0 12px 12px 0;
                            right: 0;
                            top: 0;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingImgPositions__tN4X2 div.style_PizzaTops2__c8b1F div.style_TopRightFull__2kcYW {
                            border-radius: 0 12px 12px 0;
                            right: 0;
                            top: 0;
                            background-color: white;
                        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I div.style_ToppingToEdit__1dvWG:hover{
                    /* cursor: default; */
                    /* background-color: rgba(255,0,0,0.5) !important; */
                }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU div.style_SelectedToppings__3it5I > * + * {
                margin-top: 5px;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_RightSideTabs__1K-GU > * + *{
            margin-top: 5px;
        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9{
            /* border: 1px solid red; */
            position: relative;
        }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_PizzaCenter__EayQ7{
                /* background-color: rgb(0,0,255); */
                /* border: 4px solid black; */
                z-index: 11;
                position: absolute;
                border-radius: 50%;
                height: 28px;
                width: 30px;
                top: 102px;
                right: 100px;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_TopLeftQuarter__1s8lm{
                /* background-color: rgba(123,114,23,0.5); */
                z-index: 10;
                position: absolute;
                padding: 55px;
                border-top-left-radius: 170px;
                top: 0;
                left: 0;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_TopLeftQuarter__1s8lm img{
                    transform: rotate(-90deg);
                    position: absolute;
                    width: 112px;
                    border-top-right-radius: 170px;
                    top: 4px;
                    left: 0;
                }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_TopRightQuarter__WRNgz{
                /* background-color: rgba(123,114,23,0.5); */
                z-index: 10;
                position: absolute;
                padding: 55px;
                border-top-right-radius: 170px;
                top: 0;
                right: 0;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_TopRightQuarter__WRNgz img{
                    position: absolute;
                    width: 112px;
                    border-top-right-radius: 170px;
                    top: 0;
                    right: 0;
                }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_BottomLeftQuarter__2oEOk{
                /* background-color: rgba(123,114,23,0.5); */
                z-index: 10;
                position: absolute;
                padding: 55px;
                border-bottom-left-radius: 170px;
                bottom: 7px;
                left: 0;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_BottomLeftQuarter__2oEOk img{
                    transform: rotate(180deg);
                    position: absolute;
                    width: 112px;
                    border-top-right-radius: 170px;
                    bottom: 4px;
                    left: 0;
                }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_BottomRightQuarter__3XEdh{
                /* background-color: rgba(123,114,23,0.5); */
                z-index: 10;
                position: absolute;
                padding: 55px;
                border-bottom-right-radius: 170px;
                bottom: 7px;
                right: 0;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_BottomRightQuarter__3XEdh img{
                    transform: rotate(90deg);
                    position: absolute;
                    width: 112px;
                    border-top-right-radius: 170px;
                    bottom: 4px;
                    right: 0;
                }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_LeftHalf__27Km6{
                z-index: 9;
                position: absolute;
                padding-left: 55px;
                padding-right: 55px;
                top: 0;
                left: 0;
                bottom: 7px;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_PizzaIcon__1YsJ9 div.style_RightHalf__2DCHZ{
                z-index: 9;
                position: absolute;
                padding-left: 55px;
                padding-right: 55px;
                top: 0;
                right: 0;
                bottom: 7px;
            }

div.style_Container__3q394 div.style_PizzaContainer__NH-II div.style_ChoiceIcon__3Rosw{
            /* border: 1px solid blue; */
            position: absolute;
        }

div.style_Container__3q394 div.style_ItemToppingList__tfcMs{
        /* border: 1px solid blue; */
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 70%;
        overflow: auto !important;
    }

div.style_Container__3q394 div.style_ItemToppingList__tfcMs div.style_ItemTopping__1H0fO{
            /* border: 1px solid red; */
            display: flex;
            justify-content: space-between;
            font-weight: 500;
            font-size: 15px;
        }

div.style_Container__3q394 div.style_ItemToppingList__tfcMs > * + *{
        margin-top: 10px;
    }
/* } */


/* @media (-webkit-min-device-pixel-ratio: 1) { */
div.style_Container__1j-Fu{
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    /* border: 4px solid #F6F2F2; */
    margin-top: 10px;
    border-radius: 15px;
    overflow: auto;
    /* } */
}
/* box-shadow: 0 .1vh .4vw rgba(0,0,0,.1); */
div.style_Container__1j-Fu div.style_TopContent__2rHll{
        /* border: 1px solid red; */
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
    }
div.style_Container__1j-Fu div.style_TopContent__2rHll div.style_CloseButton__3gg0h button{
                font-size: 25px;
                font-weight: 600;
                background-color: unset;
                cursor: pointer;
                border: none;
                outline: none;
            }
div.style_Container__1j-Fu div.style_TopContent__2rHll div.style_ItemName__31kMC{
            cursor: default;
            padding-right: 25px; 
            white-space: nowrap; 
            overflow: hidden;
            text-overflow: ellipsis; 

            flex: 1 1;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: var(--mainMainOrBlack);
        }
/* div.BodyContent{ */
div.style_Container__1j-Fu form{
            /* border: 4px solid red; */
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 90%;
        }
div.style_Container__1j-Fu form div.style_FormButtons__2DiNw{
                /* border: 4px solid blue; */
                margin-top: 20px;
                height: 22%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }
div.style_Container__1j-Fu form div.style_FormButtons__2DiNw div.style_PrevAndSubmitBtns__2-hY2{
                    display: flex;
                    flex-direction: column;
                    /* gap: 5px; */
                }
/* div.PrevAndSubmitBtns > * + *{
                    margin-top: 5px;
                } */
div.style_Container__1j-Fu form div.style_FormButtons__2DiNw div.style_DivProductAmount__2cxAj {
                    display: flex;
                    flex-flow: row nowrap;
                    width: 100%;
                    align-content: flex-end;
                    justify-content: center;
                    border-radius: 50px;
                    border-width: 0px;
                    height: 45px;
                    position: relative;
                    background-color: var(--mainColor);
                    max-width: 180px;
                    min-width: 100px;
                    margin: auto;
                    margin-bottom: 5px;
                }
div.style_Container__1j-Fu form div.style_FormButtons__2DiNw div.style_DivProductAmount__2cxAj div.style_PlusButton__C1U9x{
                        position: absolute;
                        margin-top: 10px;
                        margin-right: 20px;
                        right: 10px;
                        color: white;
                        line-height: 25px;
                        font-size: 33px;
                        font-weight: 300;
                        color: var(--mainWhiteOrBlack);
                        cursor: pointer;
                    }
div.style_Container__1j-Fu form div.style_FormButtons__2DiNw div.style_DivProductAmount__2cxAj div.style_ItemAmount__1G9pe{
                        /* position: absolute; */
                        margin-top: 10px;
                        align: center;
                        color: white;
                        line-height: 25px;
                        font-size: 20px;
                        font-weight: 400;
                        color: var(--mainWhiteOrBlack);
                    }
div.style_Container__1j-Fu form div.style_FormButtons__2DiNw div.style_DivProductAmount__2cxAj div.style_MinusButton__11P36{
                        position: absolute;
                        margin-top: 10px;
                        margin-left: 20px;
                        left: 10px;
                        color: white;
                        line-height: 25px;
                        font-size: 53px;
                        font-weight: 300;
                        color: var(--mainWhiteOrBlack);
                        cursor: pointer;
                    }
div.style_Container__1j-Fu form div.style_FormButtons__2DiNw button{
                    /* color: #DC143C; */
                    border-radius: 25px;
                    width: 280px;
                    max-width: 100%;
                    font-size: 25px;
                    font-weight: 500;
                    height: 40px;
                    font-family: 'Heebo', sans-serif;
                    background-color: unset;
                    /* border: 1px solid #DC143C; */
                    cursor: pointer;
                    outline: none;
                }
/* &:hover {
                        background-color: unset !important;
                    } */
div.style_Container__1j-Fu form div.style_FormButtons__2DiNw button:disabled {
                        background-color: gray;
                        pointer-events: none;
                        /* &:hover{ opacity: 0.4; } */
                    }

.style_prevButton__3HAK0 {
    color: var(--mainMainOrBlack);
    font-size: 15px;
    border: none;
    height: min-content;
    width: 'max-content';
    padding: 0;
    margin: 0;
}

.style_nextButton__2yRN8 {
    color: var(--mainMainOrBlack);
    border: 2px solid var(--mainMainOrBlack);
}

@media (-webkit-max-device-pixel-ratio: 1.1458333333333333), (max-resolution: 110dpi){
            div.style_Container__1j-Fu div.style_TopContent__2rHll div.style_ItemName__31kMC{
                font-size:25px;
            }
}

@media (-webkit-min-device-pixel-ratio: 1.3541666666666667), (min-resolution: 130dpi){
            div.style_Container__1j-Fu div.style_TopContent__2rHll div.style_ItemName__31kMC{
                font-size:15px;
            }
}

@media (-webkit-min-device-pixel-ratio: 1.5625), (min-resolution: 150dpi){
            div.style_Container__1j-Fu div.style_TopContent__2rHll div.style_ItemName__31kMC{
                font-size:13px;
            }
}

@media (max-width: 850px){
            div.style_Container__1j-Fu div.style_TopContent__2rHll div.style_ItemName__31kMC{
                font-size:25px;
            }
            div.style_Container__1j-Fu form div.style_FormButtons__2DiNw{
                height: auto;
            }
                    div.style_Container__1j-Fu form div.style_FormButtons__2DiNw div.style_PrevAndSubmitBtns__2-hY2 button{
                        font-size: 20px;
                    }
}
div.style_Container__2jzhW{
    /* border: 4px solid brown; */
    height: 100%;
    overflow: auto;
}
    
    div.style_Container__2jzhW div.style_OptionsDescription__24lc1{
        font-size: 14px;
        margin-bottom: 5px;
    }
    
    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 15% 0 15%;
    }
    
    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe{
            /* border: 4px solid pink; */
            border-bottom: 2px solid #D3D3D3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 500;
            font-size: 20px;
        }
    
    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe div.style_WrapCheckImg__3wXPP{
                display: flex;
                width: 50%;
            }
    
    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe div.style_WrapCheckImg__3wXPP input{
                    cursor: pointer;
                }
    
    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe div.style_WrapCheckImg__3wXPP div.style_itemImage__1Y1wm{
                    /* border: 1px solid blue; */
                    max-width: 6rem;
                }
    
    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv label.style_ItemOptionName__3hTdx {
            width: 50%;
            white-space: normal;
            text-align: left;
        }
    
    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv > * + *{
        margin-top: 10px;
    }



@media (-webkit-max-device-pixel-ratio: 1.1458333333333333), (max-resolution: 110dpi){
        div.style_Container__2jzhW div.style_OptionsDescription__24lc1{
            font-size: 18px;
        }
            div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe{
                font-weight: 500;
                font-size: 20px;
            }
}

@media (-webkit-min-device-pixel-ratio: 1.3541666666666667), (min-resolution: 130dpi){
        div.style_Container__2jzhW div.style_OptionsDescription__24lc1{
            font-size: 12px;
        }
            div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe{
                font-weight: 500;
                font-size: 12px;
            }

                div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe input{
                    /* width: 10px; */
                }
}

@media (-webkit-min-device-pixel-ratio: 1.5625), (min-resolution: 150dpi){
        div.style_Container__2jzhW div.style_OptionsDescription__24lc1{
            font-size: 10px;
        }
            div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe{
                font-weight: 500;
                font-size: 10px;
            }
}

@media (max-width: 850px){
        div.style_Container__2jzhW div.style_OptionsDescription__24lc1{
            font-size: 18px;
        }
            div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe{
                font-weight: 500;
                font-size: 17px;
            }
                    div.style_Container__2jzhW div.style_ItemOptionList__1QCfv div.style_ItemOption__2UNPe div.style_WrapBtnAndName__1ivam input{
                        width: 20px;
                    }
}
/* @media screen and (min-width: 601px) { */
    div.style_Container__1UF_F{
        /* border: 1px solid blue; */
        display: flex;
        flex-direction: column;
        height: 100%;
        position: relative;
        overflow: auto;
        overflow-x: hidden;
    }
    div.style_Container__1UF_F div.style_CloseButton__1nVnb {

            /* button:hover{
                animation-name: spin;
                animation-duration: 1500ms;
                animation-iteration-count: 1.07;
                animation-timing-function: ease-in-out; 
            }

            @keyframes spin {
                from {
                    transform:rotate(0deg);
                }
                to {
                    transform:rotate(360deg);
                }
            } */
        }
    div.style_Container__1UF_F div.style_CloseButton__1nVnb button{
                position: absolute;
                width: 40px;
                font-size: 25px;
                font-weight: 900;
                left: 6px;
                top: 7px;
                background-color: unset;
                color: #520E00;
                cursor: pointer;
                border: none;
                outline: none;
            }
    div.style_Container__1UF_F div.style_StepDescription__3CffU{
            margin-top: 20px;
            font-size: 20px;
            font-weight: 700;
            color: #270001;
        }
    div.style_Container__1UF_F div.style_ItemNumber__3L2fy{
            /* margin-top: 10px; */
            font-size: 20px;
            font-weight: 630;
            color: #270001;
        }
    div.style_Container__1UF_F div.style_StepsBar__3qAs2{
            /* border: 1px solid red; */
            margin: auto;
            margin-top: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            /* font-family: Consolas; */
            font-weight: 400;
            color: #dcdcdc;
            width: max-content;
            word-wrap: break-word;
            overflow: auto;
            
        }
    div.style_Container__1UF_F div.style_StepsBar__3qAs2 div.style_StepBar__2Hz-v{
                /* color: #dcdcdc; */
                width: 1.1rem;
                height: 100%;
                /* border: 1px solid #dcdcdc; */
                border-radius: var(--brdRadius);
                cursor: pointer;
            }
    div.style_Container__1UF_F div.style_LevelDescription__3GeuC{
            font-size: 20px;
            margin-top: 15px;
            margin-bottom: 15px;
            font-weight: 600;
        }
    div.style_Container__1UF_F div.style_Buttons__2xW7s{
            display: flex;
            margin-top: 25px;
            margin-bottom: 15px;
            flex-direction: column;
            justify-content: center;
            align-items: center;

        }
    /* gap: 8px; */
    div.style_Container__1UF_F div.style_Buttons__2xW7s button{
                color: #DC143C;
                border-radius: 7px;
                width: 70px;
                background-color: unset;
                border: 1px solid #DC143C;
                outline: none;
                /* margin-bottom: 25px; */
            }
    div.style_Container__1UF_F div.style_Buttons__2xW7s button:hover{
                background-color: rgba(255, 0, 0, 0.05);
                cursor: pointer;
            }
    div.style_Container__1UF_F div.style_Buttons__2xW7s > * + * {
            margin-top: 8px;
        }
    div.style_Container__1UF_F div.style_SkipContainer__ljpvY{
            display: flex;
            justify-content: center;
        }
    div.style_Container__1UF_F div.style_SkipContainer__ljpvY div.style_SkipStepButton__RJLn7{
                margin: 0;
                width: 80%;
            }
    div.style_Container__1UF_F div.style_SkipContainer__ljpvY div.style_SkipStepButton__RJLn7 div{
                    font-family: 'Heebo', sans-serif;
                    font-size: 30px;
                    color: var(--mainWhiteOrBlack);
                    height: 40px;
                    font-weight: 400;
                    border-radius: 30px;
                    cursor: pointer;
                    background-color: var(--mainColor);
                }
/* } */
/* @media screen and (min-width: 601px) { */
    div.style_Container__3MKo3{
        display: flex;
        /* border: 1px solid #e6e6e6; */
        border-radius: 20px;
        margin: 20px;
        padding: 10px;
        padding-top: 10px;
    }
    div.style_Container__3MKo3 div.style_RightElements__1xRp8{
            /* border: 1px solid blue; */
            width: 30%;
        }
    div.style_Container__3MKo3 div.style_RightElements__1xRp8 div.style_Checked__gSv9j{
                display: flex;
                justify-content: flex-start;
            }
    div.style_Container__3MKo3 div.style_MidElements__23Qkv{
            /* border: 1px solid green; */
            width: 55%;
            display: flex;
            flex-direction: column;
            position: relative;
        }
    div.style_Container__3MKo3 div.style_MidElements__23Qkv div.style_ItemDetails__nRWRc{
                /* height: 100%; */
                padding: 0;
                margin: 0;
                margin-right: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                text-align: right;
                overflow: hidden;
                line-height: 120%;
            }
    div.style_Container__3MKo3 div.style_MidElements__23Qkv div.style_ItemDetails__nRWRc div.style_ItemName__21-BI{
                    /* position: absolute; */
                    /* top: 0; */
                    font-size: 15px;
                    font-weight: 500;
                    /* overflow: hidden; */
                    /* text-overflow: ellipsis; */
                    /* width: 200px; */
                    /* text-align: right; */
                    color: var(--mainMainOrBlack);
                }
    div.style_Container__3MKo3 div.style_MidElements__23Qkv div.style_ItemDetails__nRWRc div.style_ItemPrice__2p6aT{
                    font-size: 18px;
                    font-family: 'Heebo', sans-serif;
                    font-weight: 500;
                    cursor: default;
                }
    div.style_Container__3MKo3 div.style_MidElements__23Qkv div.style_ItemButtons__1lkg7{
                display: flex;
                position: absolute;
                bottom: 0;
            }
    /* gap: 10px; */
    div.style_Container__3MKo3 div.style_MidElements__23Qkv div.style_ItemButtons__1lkg7 div.style_ItemUnitsBtn__3GUSc{
                    /* color: #DC143C; */
                    border-radius: 10px;
                    width: 60px;
                    background-color: #FFFFFF;
                    /* border: 1px solid #DC143C; */
                    outline: none;
                    color: var(--mainMainOrBlack);
                    border: 1px solid white;
                }
    div.style_Container__3MKo3 div.style_MidElements__23Qkv div.style_ItemButtons__1lkg7 div.style_ItemAddBtn__39TnR{
                    /* color: #DC143C; */
                    border-radius: 10px;
                    width: 85px;
                    background-color: #FFFFFF;
                    /* border: 1px solid #DC143C; */
                    outline: none;
                    cursor: pointer;
                    color: var(--mainMainOrBlack);
                    border: 1px solid var(--mainMainOrBlack);
                }
    div.style_Container__3MKo3 div.style_MidElements__23Qkv div.style_ItemButtons__1lkg7 > * + * {
                margin-right: 10px;
            }
    div.style_Container__3MKo3 div.style_LeftElements__1P8Jn{
            width: 15%;
        }
    div.style_Container__3MKo3 div.style_LeftElements__1P8Jn div.style_MoreInfoTooltip__2jlcM{
                font-size: 14px;
                text-decoration: underline;
                cursor: help;
                color: var(--mainMainOrBlack);
            }
    div.style_Container__3MKo3 div.style_LeftElements__1P8Jn div.style_MoreInfoTooltip__2jlcM .style_Tooltip__1bQyi{
                    max-width: 300px !important;
                    text-align: right;
                    white-space: pre-wrap !important;
                }
    div.style_Container__3MKo3 div.style_LeftElements__1P8Jn div.style_MoreInfoTooltip__2jlcM > .style_label__2Akmh{
                cursor: help;
                background-color: var(--mainMainOrBlack);
                color: #fff;
                border-radius: 10px;
                display: inline-block;
                width: 18px;
                text-align: center;
                line-height: 18px;
                font-size: 12px;
                margin-bottom: 10px;
            }
    div.style_Container__3MKo3:hover{
        background-color: #F9F9F9;
    }
/* } */

@media (max-width: 550px){
            div.style_Container__3MKo3 div.style_MidElements__23Qkv div.style_ItemName__21-BI{
                font-size: 12px;
            }
            div.style_Container__3MKo3 div.style_LeftElements__1P8Jn div.style_MoreInfoTooltip__2jlcM{
                font-size: 10px;
            }
}
div.style_Container__2pThQ{
    justify-content: center;
    position: relative;
    overflow: auto;
}

    div.style_Container__2pThQ div.style_CloseButton__293-7 {
        margin-bottom: 50px;
    }

    div.style_Container__2pThQ div.style_CloseButton__293-7 button{
            position: absolute;
            width: 40px;
            font-size: 25px;
            font-weight: 900;
            left: 6px;
            top: 7px;
            background-color: unset;
            color: #000;
            cursor: pointer;
            border: none;
            outline: none;
        }

    div.style_Container__2pThQ div.style_CartItemName__3u80v{
        margin-top: 20px;
        font-size: 20px;
        font-weight: 700;
        color: var(--mainMainOrBlack);
    }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd{
        margin-left: 7%;
        margin-right: 7%;
        margin-top: 2%;
        display: flex;
        justify-content: space-between;
        border-bottom: 2px solid #D3D3D3;
    }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_RightElements__rdCnv{
            display: flex;
            margin-bottom: 5px;
        }

    /* ItemImage{
                img{
                    width: 100px;
                } 
            } */

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_RightElements__rdCnv div.style_ItemDetails__VPF5H{
                margin: auto;
                display: flex;
                flex-direction: column;
                grid-gap: 3vh;
                gap: 3vh;
                margin-right: 25px;
            }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_RightElements__rdCnv div.style_ItemDetails__VPF5H div.style_ItemName__384ys{
                    font-size: 15px;
                    font-weight: 500;
                    color: var(--mainColor);
                }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_RightElements__rdCnv div.style_ItemDetails__VPF5H div.style_ItemQuantity__34KpE{
                    font-size: 13px;
                    font-weight: 700;
                    text-align: right;
                }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_LeftElements__2qtwS{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            position: relative;
            grid-gap: 1vh;
            gap: 1vh;
        }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_LeftElements__2qtwS div.style_MoreInfoTooltip__2lTJv{
                cursor: help;
                background-color: var(--mainMainOrBlack);
                color: #fff;
                border-radius: 10px;
                display: inline-block;
                width: 18px;
                text-align: center;
                line-height: 18px;
                font-size: 12px;
                color: var(--mainWhiteOrBlack);
                margin-bottom: 10px;
            }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_LeftElements__2qtwS div.style_MoreInfoTooltip__2lTJv .style_Tooltip__2N7eE{
                    max-width: 300px !important;
                    text-align: right;
                    white-space: pre-wrap !important;
                }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_LeftElements__2qtwS div.style_MoreInfoTooltip__2lTJv *:hover{
                    opacity: .6;
                }

    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_LeftElements__2qtwS button{
                border-radius: 10px;
                width: 100px;
                background-color: #FFFFFF;
                outline: none;
                cursor: pointer;
                color: var(--mainColor);
                border: 1px solid var(--mainColor);
            }

@media (max-width: 440px){
                /* ItemImage{
                    img{
                        width: 70px;
                    } 
                } */
                    div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_RightElements__rdCnv div.style_ItemDetails__VPF5H div.style_ItemName__384ys{
                        font-size: 13px;
                    }
                div.style_Container__2pThQ div.style_PackItemToOrder__3zbbd div.style_LeftElements__2qtwS button{
                    width: 80px;
                    font-size: 11px;
                }
}
/* @media screen and (min-width: 601px) { */
.style_Container__3siyF{
    height: max-content;

     /* > * + *{
        margin-top: 1vh;
    } */
}
.style_Container__3siyF div.style_PaymentBtns__3M4X-{
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /*
        div:nth-child(3):hover {
            cursor: default;
        } */
    }
/* width: 60%; */
.style_Container__3siyF div.style_PaymentBtns__3M4X- div{
            font-family: 'Heebo', sans-serif;
            font-size: 11px;
            height: 40px;
            font-weight: 400;
            border-radius: 30px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60%;
            margin-top: 1vh;
            position: relative;
            white-space: normal;
        }
.style_Container__3siyF div.style_PaymentBtns__3M4X- div .style_CloseBtn__16Vll{
                position: absolute;
                left: 0;
                color: var(--mainMainOrBlack);
                font-size: 22px;
                font-weight: 700;
                width: max-content;
                /* margin-inline-end: 1px; */
            }
.style_Container__3siyF div.style_PaymentBtns__3M4X- .style_couponBtn__25AvX{
            white-space: pre-wrap;
            border: 1px solid var(--mainMainOrBlack);
            color: var(--mainMainOrBlack);
            position: relative;
        }
.style_Container__3siyF div.style_PaymentBtns__3M4X- .style_couponBtn__25AvX span{
                font-weight: 700;
                text-decoration: underline;
                background-color: unset;
            }
.style_Container__3siyF div.style_PaymentBtns__3M4X- article {
            background-color: red;
            color: whitesmoke;
            white-space: normal;
            margin-top: 2px;
            margin-bottom: 0;
            width: 80%;
        }
.style_Container__3siyF div.style_PaymentBtns__3M4X- .style_goToPayment__F1ncV{
            padding-top: 2px;
            padding-bottom: 2px;
            font-size: 20px;
            font-weight: 400;
            color: var(--mainWhiteOrBlack);
            background-color: var(--mainColor);
        }
.style_Container__3siyF div.style_PaymentBtns__3M4X- :nth-child(5){
            height: 20px;
            cursor: default;
        }


@media (-webkit-min-device-pixel-ratio: 1.4583333333333333), (min-resolution: 140dpi){
    /* div.Container{
        div.PaymentBtns{
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 55%;
            
            div{
                font-size: 10px;
                font-weight: 400;
                height: 35px;
                border-radius: 20px;
                padding-top: 5px;
                padding-bottom: 1px;
            }

            div:nth-child(2){
                font-size: 17px;
            }
            
            div:nth-child(2) {
                margin-top: 5vh;
            }
        } 
        
        div.PaymentBtns > * + *{
            margin-top: 1vh;
        }
    } */
}

@media (max-width: 650px){
            div.style_Container__3siyF div.style_PaymentBtns__3M4X- div:nth-child(5) {
                margin-top: 5vh;
            }
}
div.style_Container__FAdkl{
    display: flex;
    flex-direction: column;
    background-color: rgb(223,223,223,0.97);
    /* position: relative; */
    /* top: 0; */
    /* bottom: -200%; */
    /* right: 50%;
    transform: translateX(50%); */
    z-index: 10000;
    padding: 40px;
    border-radius: 10px;
    width: 300px;
    margin: 0 auto;
}

    div.style_Container__FAdkl div.style_CouponTitle__nHkmb{
        margin-bottom: 10px;
        font-size: 14px;
    }

    /* display: block; */

    div.style_Container__FAdkl div.style_CouponInput__1CM7G input{
            text-align: center;
            background-color: unset;
            border: none;
            border-bottom: 1px solid #a5a5a5;
            /* padding: 5px 10px; */
            font-size: 20px;
            outline: none;
        }

    div.style_Container__FAdkl div.style_CouponError__KA29G span{
            color: #FF0000;
            font-size: 11px;
            margin-top: -5px;
        }

    /* div.ErrorMsg{
        position: absolute;
        top: 46%;
        left: 50%;
        font-size: 11px;
        color: #FF0000;
    } */

    div.style_Container__FAdkl div.style_CouponBtns__ETQ29{
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* gap: 1vw; */
        margin-top: 20px;
    }

    div.style_Container__FAdkl div.style_CouponBtns__ETQ29 button:nth-child(1){
            color: gray;
            border-radius: 30px;
            width: 100px;
            height: 30px;
            background-color: unset;
            border: 1px solid gray;
            outline: none;
        }

    div.style_Container__FAdkl div.style_CouponBtns__ETQ29 button:nth-child(2){
            color: var(--mainWhiteOrBlack);
            border-radius: 30px;
            width: 100px;
            height: 30px;
            /* border: 1px solid gray; */
            /* border: unset; */
            outline: none;
        }

    div.style_Container__FAdkl div.style_CouponBtns__ETQ29 button.style_CouponOff__r0CmR {
            background-color: rgb(255, 255, 255, 0.07);
            border: 1px solid #a5a5a5;
        }

    div.style_Container__FAdkl div.style_CouponBtns__ETQ29 button.style_CouponOn__2PaJD {
            background-color: var(--mainColor);
            border: unset;
        }

    div.style_Container__FAdkl div.style_CouponBtns__ETQ29 button:hover{
            cursor: pointer;
        }

    div.style_Container__FAdkl div.style_TimeBar__37EwZ {
        position: relative;
    }

    div.style_Container__FAdkl div.style_TimeBar__37EwZ span{
            position: absolute;
            left:50px;
            font-size: 10px;
            color: rgb(0, 0, 0, 0.4);
            font-weight: 600;
        }

div.style_ModalContainer__8EiMk{
    margin-bottom: 10px;
}
    div.style_ModalContainer__8EiMk div.style_CloseBtn__wA7Ac{
        text-align: left;
        color: #808080;
        font-weight: 700;
        font-size:23px;
    }
    div.style_ModalContainer__8EiMk div.style_CloseBtn__wA7Ac span:hover{
            cursor: pointer;
        }
    div.style_ModalContainer__8EiMk div.style_Body__2q-jN{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* gap: 10px; */
    }
    /* margin-top: 15px; */
    div.style_ModalContainer__8EiMk div.style_Body__2q-jN div.style_ModalTitle__VFNqH{
            color: #808080;
            font-size: 25px;
            font-weight: 400;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            text-align: center;
        }

div.style_Container__O7xd7{
    text-align: center;
    margin: 0;
    padding: 0;
}

    div.style_Container__O7xd7 div.style_CancleXBtn__8Jayx{
        text-align: left;
    }

    div.style_Container__O7xd7 div.style_CancleXBtn__8Jayx span{
            font-size: 22px;
        }

    div.style_Container__O7xd7 div.style_CancleXBtn__8Jayx span:hover{
            cursor: pointer;
        }

    div.style_Container__O7xd7 div.style_TextContnet__mQW2V div.style_Title__22N4- span{
                font-weight: 550;
                font-size: 23px;
            }

    div.style_Container__O7xd7 div.style_TextContnet__mQW2V div.style_TowRowText__2X7IK{
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            color: #808080;
            font-size: 19px;
            font-weight: 500;
        }

    div.style_Container__O7xd7 div.style_ContinueBtn__1UgIz{
        display: flex;
        justify-content: center;
        margin-top: 35px;
    }

    div.style_Container__O7xd7 div.style_ContinueBtn__1UgIz span{
            border: 1px solid #808080;
            border-radius: 20px;
            width: 55%;
        }

    div.style_Container__O7xd7 div.style_ContinueBtn__1UgIz span:hover{
            cursor: pointer;
        }
div.style_ModalContainer__1fpJG{
    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

    div.style_ModalContainer__1fpJG div.style_Header__WvYmv{
        /* border: 1px solid blue; */
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
    }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR{
        /* border: 1px solid green; */
        margin: auto;
        width: 90%; 
        max-width: 550px;
        height: 80%;
        overflow: auto;
        
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc{
            /* border: 4px solid red; */
            /*width: 10rem;*/
            /* height: 15rem; */
            flex: 0 1 50%;
            cursor: pointer;
            padding-left: 1rem;
            padding-right: 1rem;
            max-width: 250px;
        }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ{
                /* border: 4px solid purple; */
                margin: auto;
                /*max-width: 50%;*/
                position: relative;
                text-align: center;
            }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi{
                    position: absolute;
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    align-content: center;
                    flex-flow: wrap;
                    top: 0;
                    border-radius: 50%;
                    background-color: var(--mainColor);
                    color: var(--mainWhiteOrBlack);
                    font-family: 'Heebo';
                    height: 6rem;
                    width: 6rem;
     
                }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi div.style_BigPrice__2i50Y {
                        display: flex;
                    }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi .style_Units__3LZqH {
                        font-size: 10px;
                        line-height: 10px;
                    }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi div.style_Agorot__onfFI{
                        margin-bottom: 30%;
                        font-size: .5em;
                        line-height: .0rem;
                        height: 0.8rem;
                    }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi div.style_Shekels__3t2Dj{
                        font-size: 1.3em;
                        line-height: 0.8rem;
                        height: 0.8rem;
                        
                    }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi div.style_Curr__1SMuP{
                        font-size: 0.8em;
                        line-height: 1rem;
                        height: 0.8rem;
                    }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ span img {
                        max-width: 150px;
                        max-height: 100px;
                        height: 100px;
                    }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemName__3fdXN{
                /* border: 1px solid red; */
                margin: auto;
                text-align: center;
                width: 60%;
                overflow: hidden;
                text-overflow: ellipsis;
                font-size: 1.1rem;
                font-weight: 500;
                color: var(--mainMainOrBlack);
            }

    div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc:hover{
            opacity: .8;
        }

    div.style_ModalContainer__1fpJG div.style_NoThanksFooter__174W3{
        /* border: 1px solid green; */
        margin-top: 3rem;
        display: flex;
        justify-content: center;
    }

    div.style_ModalContainer__1fpJG div.style_NoThanksFooter__174W3 span{
            /* border: 1px solid red; */
            font-size: 1.8rem;
            color: #7d7d7d;
            text-decoration: underline;
            cursor: pointer;
        }

    div.style_ModalContainer__1fpJG div.style_NoThanksFooter__174W3 span:hover{
            opacity: .7;
        }

@media (max-width: 600px){
        div.style_ModalContainer__1fpJG div.style_Header__WvYmv{
            font-size: 1.5rem;
        }
    
        div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR{
            max-width: 100%;
        }
           
            div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc{
                max-width: 100%;
            }
                    /*max-width: 75%;
                    padding-right: 5%;*/
                        
                        div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi div.style_Agorot__onfFI{
                        }
    
                        div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi div.style_Shekels__3t2Dj{
                        }
                        div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemImage__7NHhZ div.style_PriceCircle__3FXJi div.style_Curr__1SMuP{
                        }
    
                div.style_ModalContainer__1fpJG div.style_CartProducts__3S_uR div.style_ItemContainer__3JHCc div.style_ItemName__3fdXN{

                }

            div.style_ModalContainer__1fpJG div.style_NoThanksFooter__174W3 span{

            }
}
div.style_ModalContainer__1bN0W{
    margin-bottom: 10px;
    position: relative;

}

    div.style_ModalContainer__1bN0W div.style_CloseBtn__1j8RN{
        text-align: left;
        color: #808080;
        margin-left: 8px;
        font-weight: 700;
        font-size: 25px;
    }

    /* cursor: pointer; */

    div.style_ModalContainer__1bN0W div.style_CloseBtn__1j8RN span:hover{
            cursor: pointer;
        }

    div.style_ModalContainer__1bN0W div.style_Body__HyMOB{
        display: flex;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    
    }

    /* margin-top: 15px; */

    div.style_ModalContainer__1bN0W div.style_Body__HyMOB div.style_ModalTitle__3inks{
            /* color: #808080; */
            margin-top: 30px;
            color: #000;
            font-size: 25px;
            font-weight: 400;
            text-align: center;
            /* display: flex; */
            /* flex-direction: column; */
            /* justify-content: center; */
            /* align-items: center; */
        }

    /* gap: 10px; */

    div.style_ModalContainer__1bN0W div.style_Body__HyMOB div.style_RowText__2Resm{
            margin-top: 15px;
            color: #808080;
            font-size: 19px;
            font-weight: 500;
            text-align: center;
        }

    div.style_ModalContainer__1bN0W button{
        margin-top: 18px;
        font-family: Heebo;
        width:185px;
        height:45px;
        border-radius: 20px;
        font-size: 18px;
        align-text: center;
        font-weight: 600;

        outline: none; 
        cursor: pointer;
        color: var(--mainColor);
        border: 1px solid var(--mainColor);
    }

div.style_ModalContainer__2hdQX{
   padding: 5px;

}

    div.style_ModalContainer__2hdQX div.style_CloseBtn__2lDl4{
        text-align: left;
        color: #808080;
        margin-left: 8px;
        font-weight: 700;
        font-size: 25px;
    }

    /* cursor: pointer; */

    div.style_ModalContainer__2hdQX div.style_CloseBtn__2lDl4 span:hover{
            cursor: pointer;
        }

    div.style_ModalContainer__2hdQX div.style_Body__3umaA{
        display: flex;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    /* margin-top: 15px; */

    div.style_ModalContainer__2hdQX div.style_Body__3umaA div.style_ModalTitle__1_lSD{
            /* color: #808080; */
            margin-top: 30px;
            color: #000;
            font-size: 25px;
            font-weight: 400;
            text-align: center;
            /* display: flex; */
            /* flex-direction: column; */
            /* justify-content: center; */
            /* align-items: center; */
        }

    /* gap: 10px; */

    div.style_ModalContainer__2hdQX div.style_Body__3umaA div.style_RowText__AKr9K{
            margin-top: 15px;
            color: #808080;
            font-size: 19px;
            font-weight: 500;
            text-align: center;
        }

    div.style_ModalContainer__2hdQX div.style_Body__3umaA button {
            color: var(--mainMainOrBlack);
            border: 1px solid var(--mainMainOrBlack);
        }

    div.style_ModalContainer__2hdQX button{
        margin-top: 18px;
        font-family: Heebo;
        width:185px;
        height:45px;
        border-radius: 20px;
        font-size: 18px;
        text-align: center;
        font-weight: 600;
        border-radius: 50px;
        outline: none; 
        background-color: unset;
        cursor: pointer;
    }

div.style_Container__14s3-{
    /* border: 1px solid red; */
    /* display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 70vh; */
    /* min-height: 100%; */

    /* div.Title{
        margin: 15px;
        color: #FFFF;
        font-size: 25px;
    } */
    margin-top: 10px;
    z-index: 1;
}

    
    div.style_Container__14s3- div.style_BtnGroup__InYeV{
        /* border: 1px solid red; */
        height: 100%;
        max-width: 340px;
        margin: auto;

    }

    
    div.style_Container__14s3- div.style_BtnGroup__InYeV div.style_OptionsBtns__2KSL0{
            display: flex;
            justify-content: center;
        }

    
    /* margin-right: 15px; */

    
    div.style_Container__14s3- div.style_BtnGroup__InYeV div.style_OptionsBtns__2KSL0 button{
                /* margin-left: 15px; */
                width: 100%;
                min-height: 30px;
                font-size: 15px;
                outline: none;
                /* color: #FFFF; */
                border: solid;
                border-width: 1px;
                /* border-radius: 3px; */
                font-family: 'Heebo', sans-serif;
                text-decoration: none;
                border-color: var(--mainMainOrBlack);
            }

    
    div.style_Container__14s3- div.style_BtnGroup__InYeV div.style_OptionsBtns__2KSL0 button .style_Tooltip__lR4EF{
                    max-width: 400px !important;
                    text-align: right;
                    white-space: pre-wrap !important;
                }

    
    div.style_Container__14s3- div.style_BtnGroup__InYeV div.style_OptionsBtns__2KSL0 button:hover{
                opacity: .9;
            }

    
    div.style_Container__14s3- div.style_BtnGroup__InYeV div.style_OptionsBtns__2KSL0 .style_choosenButton__2zN5M{
                background-color: var(--mainMainOrBlack);
                color: #FFF;
            }

    
    div.style_Container__14s3- div.style_BtnGroup__InYeV div.style_OptionsBtns__2KSL0 .style_unchoosenButton__2WSyL{
                background-color: #fff;
                color: var(--mainMainOrBlack);
                cursor: 'pointer';
            }

    
    div.style_Container__14s3- div.style_BtnGroup__InYeV div.style_OptionBtns__3gFGa > * + *{
            margin-right: 3%;
        }



  
@media (max-width: 850px){
    div.style_Container__14s3- {
        width: 100%;
    }

        div.style_Container__14s3- div.style_BtnGroup__InYeV{
            width: 90%;
            max-width: inherit;
        }

        div.style_Container__14s3- div.style_Title__1bhC_{
            font-size: 15px;
        }

        div.style_Container__14s3- div.style_UserChoosing__3DiGS{
            /* form{
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                align-items: center;

                input, select, textarea{
                    width: 260px;
                }

                .Btns{
        
                    button{
                        margin-top: 10px;
                        width: 250px;
                        height: 30px;
                        font-size: 19px;
                    }
                    
                    button:nth-child(2){
                        width: 250px;
                        height: 30px;
                    }
                } */
        }
}

div.style_LoaderContainer__1Qgkg{
  justify-self: right;
  flex-flow: column;
  flex: 1 1;
  margin: 0;
  border-radius: var(--brdRadius);
  background-color: var(--mainWhite);
  padding: 15px;
  min-height: 140px;
}

/* @media screen and (min-width: 601px) { */
  div.style_Container__3XA4I{
    justify-self: right;
    flex-flow: column nowrap;
    /* max-width: 60vw; */
    max-height: 100%;
    margin-bottom: 50px;
    flex: 4 4;
    overflow-x: auto;
    /* white-space: nowrap; */
    margin: 5px 5px 0 5px;
    border-radius: var(--brdRadius);

    
  }
  div.style_Container__3XA4I div.style_TopDiv__24gMi {
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      padding-bottom: 200px;
    }
  div.style_Container__3XA4I div.style_TopDiv__24gMi div.style_SearchCategoryLine__15jqD {
        background-color: var(--mainColor);
        border-radius: var(--brdRadius);
        color: var(--mainWhiteOrBlack);
        margin: 0 0 5px 0;
        min-height: 30px;
        padding: 5px 10px;
        text-align: start;
        white-space: pre-line;
      }
  div.style_Container__3XA4I div.style_TopDiv__24gMi div.style_Oops__Hm9hW{
        /* border: 1px solid red; */
        margin: auto;
        margin-top: 20vh;
        color: var(--mainWhiteOrBlack);
      }
  div.style_Container__3XA4I div.style_TopDiv__24gMi div.style_Oops__Hm9hW div.style_title__2NuaZ{
          text-align: center;
          font-size: 3.5vw;
        }
  div.style_Container__3XA4I div.style_TopDiv__24gMi div.style_Oops__Hm9hW div.style_subtitle__2wmmU{
          text-align: center;
          font-size: 1.7vw;
        }

  

  @media (max-width: 600px) {
    div.style_Container__3XA4I {
      max-height: 68vh;
    }
  }

  @media (max-width: 850px) {
      div.style_Container__3XA4I div.style_couponMobile__3LbAK {
        position: absolute;
        left: 0px;
        bottom: 200px;
        color: var(--mainWhiteOrBlack);
        font-size: 16px;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 25px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        cursor: pointer;
        border: 2px solid var(--mainWhiteOrBlack);
        border-left: none;
        background-color: var(--mainColor);
      }
  }

  @media (min-width: 851px) {
      div.style_Container__3XA4I div.style_couponMobile__3LbAK {
        display: none;
      }
  }
  div.style_Container__28A55{
    justify-self: right;
    flex-flow: column nowrap;
    flex: 1 1;
    margin: 0 0 5px 0;
    border-radius: var(--brdRadius);
    background-color: var(--mainWhite);
    position: relative;
  }
  
    div.style_Container__28A55:last-of-type {
      margin-bottom: 0px;
    }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE {
      /* text-align: center; */
      display: flex;
      flex: 1 1;
      min-height: 120px;
      flex-flow: row wrap;
      justify-content: space-between;
      color: black;
      padding: 5px;
    }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivLeft__PEDD0 {
        flex: 0 0 180px;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;
        align-content: center;
        padding: 5px;
      }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp {
        display: flex;
        justify-content: flex-start;
        flex-flow: column nowrap;
        flex: 3 3;
      }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemName__3OpHN {
          display: flex;
          word-wrap: break-word;
          padding-top: 5px;
          font-size: 22px;
          text-align: start;
          color: var(--mainMainOrBlack);
        }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemName__3OpHN .style_UnitMeasurement__39MgN {
            color: #000;
          }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemName__3OpHN .style_UnitMeasurement__39MgN small {
              font-size: 16px;
            }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemName__3OpHN div {
            word-break: break-word;
          }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftUp__2gtHD {
          /* border: 1px solid red; */
          color: var(--mainColor);
          display: flex;
          justify-content: flex-end;
          flex-grow: 1;
        }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy {
            position: relative;
            height: 30px;
            /* width: 100px; */
            text-align: left;
            cursor: help;
            font-size: 16px;
          }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_label__33-E9{
              cursor: help;
              background-color: var(--mainMainOrBlack);
              color: #fff;
              border-radius: 10px;
              display: inline-block;
              width: 18px;
              text-align: center;
              line-height: 18px;
              font-size: 15px;
              color: white;
              margin-bottom: 10px;
            }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_Tooltip__1Sc0s{
              max-width: 300px !important;
              text-align: right;
              white-space: pre-wrap !important;
            }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL {
              position: fixed;
              max-width: 500px;
              z-index: 1;
            }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescArrow__1HPRO {
                height: 15px;
                width: 15px;
                transform: rotate(45deg);
                border: 1px solid var(--black);
                position: absolute;
                left: -7px;
                top: 24px;
                z-index: 1;
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescArrow2__9MqhF {
                height: 13px;
                width: 13px;
                transform: rotate(45deg);
                position: absolute;
                left: -6px;
                top: 25px;
                z-index: 3;
                background-color: var(--mainWhite);
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescOuterPopupIn__2-4mM {
                border: 1px solid var(--black);
                display: inline-block;
                padding: 10px;
                padding-left: 15px;
                padding-right: 15px;
                background-color: var(--mainWhite);
                text-align: right;
                position: sticky;
                z-index: 2;
                color: var(--black);
                border-radius: var(--brdRadius);
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescOuterPopupIn__2-4mM .style_DescPopupIcon__1Bkme {
                  width: 100%;
                  height: 30px;
                }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftUp__2gtHD .style_DescOuterDiv__6RApy .style_DescOuterPopup__MRmuL .style_DescOuterPopupIn__2-4mM .style_DescPopupText__2SazR {
                  white-space: pre-wrap;
                }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftUp__2gtHD > * + * {
          margin-right: 20px;
        }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd {
          display: flex;
          align-items: flex-end;
          flex-flow: wrap;
        }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft1__1c_PJ {
            flex: 1 1;
            min-width: 100px;
          }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft1__1c_PJ div.style_ItemPrice__32wT- {
              flex: 2 2;
              text-align: start;
              /* justify-content: center; */
              padding-top: 26px;
              font-size: 26px;
              font-weight: 600;
            }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft1__1c_PJ div.style_ItemPrice__32wT- .style_UnitMeasurementText__dBvZL {
                font-size: 20px;
                font-weight: 400;
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft1__1c_PJ div.style_ItemPrice__32wT- .style_UnitMeasurementText__dBvZL small {
                  font-size: 16px;
                }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI {
            flex: 1 1;
            max-width: 180px;
            min-width: 100px;
          }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_ItemUnits__1yR53 {
              /* align-self: center; */
              /* display: flex;
              gap: 10px; */
              padding-left: 5px;
            }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_ItemUnits__1yR53 div.style_Unit__3UDVr{
                width: 80px;
                height: 22px;
                font-size: 20px;
                border-radius: var(--brdRadius);
                border:1px solid var(--mainMainOrBlack);
                margin-bottom: 5px;

                font-size: 17px;
                line-height: 23px;
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_ItemUnits__1yR53 div.style_Unit__3UDVr:hover{
                cursor: default;
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg {
              display: flex;
              flex-flow: row nowrap;
              width: 100%;
              align-content: flex-end;
              justify-content: center;
              border-radius: 50px;
              border-width: 0px;
              height: 45px;
              position: relative;
              background-color: var(--mainColor);
            }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg div.style_BtnLeftButton__1sEoN {
                align-self: center;
                justify-self: center;
                white-space: nowrap;
                padding: 5px 0 5px 0;
                font-size: 24px;
                color: var(--mainWhiteOrBlack);
                border-radius: 50px;
                border-width: 0px;
                margin-left: 15px;
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg div.style_BtnLeftButton__1sEoN:hover{
                cursor: pointer;
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg div.style_PlusLeftButton__2vwgt {
                margin-top: 10px;
                margin-left: 5px;
                width: 25px;
                height: 25px;
                color: white;
                line-height: 25px;
                font-size: 43px;
                font-weight: 300;
                color: var(--mainWhiteOrBlack);
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg div.style_PlusLeftButton__2vwgt:hover{
                cursor: pointer;
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg div.style_PlusButton__27SSb{
                position: absolute;
                margin-top: 10px;
                margin-right: 20px;
                right: 10px;
                color: white;
                line-height: 25px;
                font-size: 33px;
                font-weight: 300;
                color: var(--mainWhiteOrBlack);
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg div.style_ItemAmount__2c1RW{
                /* position: absolute; */
                margin-top: 10px;
                align: center;
                color: white;
                line-height: 25px;
                font-size: 20px;
                font-weight: 400;
                color: var(--mainWhiteOrBlack);
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg div.style_MinusButton__33NZU{
                position: absolute;
                margin-top: 10px;
                margin-left: 20px;
                left: 10px;
                color: white;
                line-height: 25px;
                font-size: 53px;
                font-weight: 300;
                color: var(--mainWhiteOrBlack);
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg div.style_MinusButton__33NZU:hover{
                cursor: pointer;
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg div.style_PlusButton__27SSb:hover{
                cursor: pointer;
              }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-evenly;
        align-content: center;
        flex: 2 2;
        max-width: 240px;
        margin: 5px;
      }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_ItemImg__3Ws8H {
        cursor: pointer;
        }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_ItemImg__3Ws8H img.style_ItemImg__3Ws8H {
            max-width: 135px;
            height: 90px;
            margin: 0px;
          }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_ItemFlags__2FsAV {
          display: flex;
          text-align: center;
          justify-content: center;
        }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_ItemFlags__2FsAV .style_label__33-E9{
            cursor: help;
            /* background-color: #fff; */
            /* color: #000; */
            border-radius: 10px;
            width: 18px;
            text-align: center;
            line-height: 18px;
            font-size: 15px;
            position: absolute;
            left: 5px;
            top: calc(50% - 9px);;
          }
  
    div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU div.style_iFlags__2jAOm {
          position: relative;
          color: var(--mainWhite);
          width: 80%;
          max-width: 200px;
          border-radius: var(--brdRadius);
          height: auto;
          font-size: 15px;
          line-height: 16px;
          font-weight: 500;
          padding: 4px 8px 4px 26px;
          /* border:1px solid  #E10000; */
          background-color: #E10000;
          /* &.iNew {
            border:1px solid  #FF8000;
            background-color: #FF8000;
          }
  
          &.iSale {
            border:1px solid  #E10000;
            background-color: #E10000;
          }
  
          &.iSeason {
            border:1px solid  #FF8000;
            background-color: #FF8000;
          } */
        }
  
    div.style_Container__28A55 div.style_DivNotInStock__3QKGD {
      position: absolute;
      overflow: hidden;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      color: var(--mainWhite);
      border-radius: var(--brdRadius);
      background-color: rgba(70, 70, 70, 0.8); 
      font-size: 7vw;
      font-weight: 600;
    }
  
    div.style_Container__28A55 div.style_DivNotInStock__3QKGD div.style_Note__3RXzj {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        white-space: nowrap;
        text-overflow: ellipsis;
      }

div.style_TechDescOuterDiv__1y91B {
  cursor: pointer;
  color: var(--mainColor);
  -webkit-text-decoration: underline var(--mainColor);
          text-decoration: underline var(--mainColor);
}

/* @media screen and (max-width: 600px) {
    div.TopDiv {
      display: flex;
      flex-flow: row wrap;
    }
    div.ItemIcon {
      width: 50px;
      max-width: 50px;
      max-height: 50px;
      border:1px solid  var(--mainWhite);
      border-radius: 50%;
    }
    img.ItemImg {
      width: 50px;
      height: 50px;
      margin: 0px;
    }
  } */

  @media (max-width: 600px) {
      div.style_Container__28A55 div.style_TopDiv__1fQSE {
        min-height: 100px;
      }
          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivRight__cqUZU img.style_ItemImg__3Ws8H{
            max-width: 100px;
            height: 70px;
          }
        div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp {
          flex: 4 4;
        }

          div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_ItemName__3OpHN {
            font-size: 16px;
          }
            div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftUp__2gtHD div.style_DescOuterDiv__6RApy {
              font-size: 14px;
            }
              div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft1__1c_PJ div.style_ItemPrice__32wT- {
                font-size: 20px;
                padding-top: 10px;
              }
              div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg {
                height: 22px;
                margin-bottom: 5px;
              }

                div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg div.style_PlusButton__27SSb {
                  margin-top: 0;
                  margin-right: 5px;
                  font-size: 23px;
                }

                div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg div.style_ItemAmount__2c1RW {
                  margin-top: 0;
                  font-size: 15px;
                }

                div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg div.style_MinusButton__33NZU {
                  margin-top: 0;
                  margin-left: 5px;
                  font-size: 23px;
                }

                div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg div.style_BtnLeftButton__1sEoN {
                  font-size: 15px;
                }
                
                div.style_Container__28A55 div.style_TopDiv__1fQSE div.style_DivMid__yB7hp div.style_DivLeftLeft__2Dkjd div.style_DivLeftLeft2__BpMQI div.style_DivLeftBottom__1SvMg div.style_PlusLeftButton__2vwgt {
                  font-size: 23px;
                  line-height: 3px;
                }
  }
div.style_Container__2QRbD{
    display: flex;
    width: 100%;
    justify-content: center;
}

    div.style_Container__2QRbD div.style_UnitTypes__3n59c {
        background: #D9D9D9;
        border-radius: var(--brdRadius);;
        display: flex;
        padding: 3px 4px;
        margin-bottom: 10px;
    }

    div.style_Container__2QRbD div.style_UnitSel__3fMuV{
        width: 60px;
        height: 22px;
        font-size: 15px;
        line-height: 21px;
        border-radius: var(--brdRadius);
        border:1px solid var(--mainMainOrBlack);
        font-size: 17px;
        background-color: var(--mainMainOrBlack);
        color: #fff;
        cursor: pointer;
    }

    div.style_Container__2QRbD div.style_UnitNotSel__1D9Kh{
        width: 60px;
        height: 22px;
        font-size: 15px;
        line-height: 21px;
        border-radius: var(--brdRadius);
        /* border:1px solid var(--mainMainOrBlack); */
        font-size: 17px;
        background-color: unset;
        color: #000;
        cursor: pointer;
    }

    div.style_Container__2QRbD div.style_Unit__3pePf:hover{
        cursor: pointer;
    }

div.style_Container__2QRbD > * + *{
    margin-right: 5px;
}
div.style_Container__1vjeN{
    /* border: 1px solid red; */
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
}

    div.style_Container__1vjeN div.style_cancleBtn__Q9Dvp{
        position: absolute;
        font-size: 25px;
        cursor: pointer;
        left: 10px;
        top: 10px;
    }

    div.style_Container__1vjeN div.style_Content__1X6bQ{
        /* border: 1px solid red; */
        width: 80%;
        text-wrap: wrap;
        margin: auto;
        align-text: right;
        word-break: break-word;
    }
.style_container__1No6B{
    direction: ltr;
    margin-bottom: 5vh;
}

    .style_container__1No6B .style_closeBtn__1dO3c{
        margin: .7vh 0 0 .6vw;
        font-size: 1.3vw;
        cursor: pointer;
        width: min-content;
    }

    .style_container__1No6B .style_body__1idoB{
        /* border: 1px solid red; */
        margin: auto;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .style_container__1No6B .style_body__1idoB .style_itemImage__18eWY{
            width: 20%;
        }

    .style_container__1No6B .style_body__1idoB .style_itemName__g92qm{
            margin-top: 1vh;
            font-size: 1.3vw;
            font-weight: 600;
        }

    .style_container__1No6B .style_body__1idoB .style_details__2PhPJ{
            /* border: 1px solid red; */
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
        }

    .style_container__1No6B .style_body__1idoB .style_details__2PhPJ .style_pair__2jeyM{
                /* border: 1px solid red; */
                width: 100%;
            }

    .style_container__1No6B .style_body__1idoB .style_details__2PhPJ .style_pair__2jeyM .style_detail__2fgEo{
                    /* border: 1px solid red; */
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
    
                    margin: 1vw;
                }

    .style_container__1No6B .style_body__1idoB .style_details__2PhPJ .style_pair__2jeyM .style_detail__2fgEo .style_detailTitle__3o5Jt{
                        font-size: 1vw;
                        font-weight: 600;
                    }

    .style_container__1No6B .style_body__1idoB .style_details__2PhPJ .style_pair__2jeyM .style_detail__2fgEo .style_detailContent__Bqd0E{
                        font-size: 1vw;
                    }

    .style_container__1No6B .style_body__1idoB .style_comments__1tBfO{
            /* border: 1px solid red; */
            width: 100%;
            margin-top: 5vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

    .style_container__1No6B .style_body__1idoB .style_comments__1tBfO .style_titleComments__1XZqu{
                font-size: 1vw;
                font-weight: 600;
            }

    .style_container__1No6B .style_body__1idoB .style_comments__1tBfO .style_bodyComments__2-Rtd{
                font-size: 1vw;
                width: 60%;
                word-wrap: break-word;
                text-align: center;
            }

@media (max-width: 850px){
        .style_container__1No6B .style_closeBtn__1dO3c{
            font-size: 3vw;
            margin-left: 1.2vw;
        }

        .style_container__1No6B .style_body__1idoB{
            width: 90%;
        }
            .style_container__1No6B .style_body__1idoB .style_itemName__g92qm{
                font-size: 3vw;
                color: var(--mainColor);
            }
                        .style_container__1No6B .style_body__1idoB .style_details__2PhPJ .style_pair__2jeyM .style_detail__2fgEo .style_detailTitle__3o5Jt{
                            font-size: 1.8vw;
                        }
                        .style_container__1No6B .style_body__1idoB .style_details__2PhPJ .style_pair__2jeyM .style_detail__2fgEo .style_detailContent__Bqd0E{
                            font-size: 1.7vw;
                        }
                .style_container__1No6B .style_body__1idoB .style_comments__1tBfO .style_titleComments__1XZqu{
                    font-size: 2vw;
                }
                .style_container__1No6B .style_body__1idoB .style_comments__1tBfO .style_bodyComments__2-Rtd{
                    font-size: 1.9vw;
                }
}
div.style_Container__3beQT{
  justify-self: right;
  flex-flow: column nowrap;
  margin: 0 0 5px 0;
  padding: 5px;
  border-radius: var(--brdRadius);
  background-color: var(--mainColor);
  min-height: 30px;
  color: var(--mainWhiteOrBlack);

  /* div.TopDiv {
    flex-flow: row wrap;
  } */
}

div.style_Container__AKAn2 {

    /* position: relative; */


  }

  div.style_Container__AKAn2 .style_CallToAction__2volz {
    float: inline-start;
    margin: 3px 15px -6px 0;
  }

  div.style_Container__AKAn2 .style_CallToAction__2volz svg {
      width: 20px;
    }

  div.style_Container__AKAn2 {
    justify-self: right;
    flex-flow: column nowrap;
    flex: 1 1;
    margin: 0 0 5px 0;
    border-radius: var(--brdRadius);
    background-color: var(--mainColor);
    min-height: 30px;
    color: var(--mainWhiteOrBlack);
    padding: 5px;
    white-space: pre-line;


}

  div.style_Container__AKAn2 .style_timeSign__1i-VA {
        /* flex: 1;
        flex-flow: row wrap; */
       
    }

  /* &:last-of-type {
    margin-bottom: 0px;
  } */

  div.style_Container__AKAn2 div.style_whDiv__1NuN0 {
      /* text-align: center; */
      /* display: flex; */
      flex: 1 1;
      min-height: 30px;
      flex-flow: row wrap;
      /* justify-content: space-between; */
      color: var(--mainWhite);
      padding: 5px;
      white-space: pre-line;
    }


.style_NavArrow__2edRu {
    position: absolute;
    width: 90%;
    background-color: white;
    height: 20px;
    opacity: 0.7;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 850px) {
    .style_NavArrow__2edRu {
        width: 30px;
        height: 90%;
      }
  } 
.style_Container__2mrMX{
  /* align-self: center; */
  justify-self: right;
  flex-flow: column nowrap;
  height: 100%;
  /* max-height: calc(100vh - 6.3%); */
  /* max-height: 600px; */
  margin-top: 5px;
  /* flex: 1; */
  /* overflow-y: hidden; */
  /* white-space: nowrap; */
  /* border-radius: 0 var(--brdRadius) var(--brdRadius) 0; */
  background-color: var(--mainColor);
  position: relative;
  width: 100px;
  padding-top: 5px;
  padding-bottom: 5px;

}
  
  .style_Container__2mrMX .style_ItemList__2B05i {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: normal;
    max-width: 100px;
    /* //padding: 20px; */
    /* //margin: 0 5px; */
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }


/* .TopDiv button {
  background-color: transparent;
  color: var(--mainWhite);
  border: 0px;
} */


@media screen and (max-width: 850px) {
  .style_Container__2mrMX{
    position: fixed;
    bottom: 0;
    width: 100vw;
    /* border-radius: 8px 8px 0 0; */
    /* background-color: var(--mainColor); */
    display: flex;
    justify-content: center;
    /* overflow-x: auto;
    overflow-y: hidden; */
    height: 100px;
    margin-top: 0;
    border-radius:0;
  }
    
    .style_Container__2mrMX .style_ItemList__2B05i {
      //margin-top: 5px;
      /* display: flex; */
      flex-flow: row nowrap;
      width: 100vw;
      max-width: 100vw;
      /* gap: 15px; */
      scrollbar-height: 3px;
      overflow-y: hidden;
      overflow-x: auto;
    }

    .style_Container__2mrMX div.style_ItemLIst__eT_Jp > *{
      padding-left: 12px;
    }

    .style_Container__2mrMX div.style_ItemList__2B05i:nth-child(1){
      padding-right: 12px;
    }

    .style_Container__2mrMX div.style_ItemList__2B05i::-webkit-scrollbar{
      height: 3px;
    }


}


@media (max-height: 300px) {
  div.style_Container__2mrMX{
    height: 0;
  }
}
@media (min-width: 851px) {
  div.style_Container__3ttpU{
    align-self: center;
    justify-self:right;
  }
  
    div.style_Container__3ttpU div.style_TopDiv__2UgMX {
      text-align: center;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-around;
      color: var(--mainWhiteOrBlack);
      margin-bottom: 15px;
      cursor: pointer;
    }
        div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_Cat__1f3jW div.style_ItemIcon__1feJp {
          width: 66px;
          height: 66px;
          border-radius: 50%;
          border: 2px solid var(--mainWhiteOrBlack);
        }
      
          div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_Cat__1f3jW div.style_ItemIcon__1feJp.style_ItemIconSelected__2A00s {
            background-color: var(--mainWhite);
          }
    
          div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_Cat__1f3jW div.style_ItemIcon__1feJp img.style_ItemImg__3A7lq {
            max-width: 56px;
            max-height: 56px;
            margin-top: 5px;
          }
      
        div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_Cat__1f3jW div.style_ItemName__57Cy6 {
          width: 100px;
          margin-left: -16px;
          margin-right: -16px;
          font-weight: 300;
          font-size: 21px;
          overflow-wrap: break-word;
        }

    div.style_Container__3ttpU div.style_SubcatName__1q5iM{
      color: #FFF;
      margin-bottom: 15px;
      max-width: 68px;
      word-break: break-word;
      overflow: hidden;
    }
      div.style_Container__3ttpU:last-of-type div.style_TopDiv__2UgMX {
        margin-bottom: 0px;
      }
}

@media (max-width: 850px) {
  div.style_Container__3ttpU{
    /* border: 1px solid blue; */

    /* align-self: center;
    justify-self:right; */
    height: 100%;
    max-height: 100%;
  }
    div.style_Container__3ttpU div.style_TopDiv__2UgMX {
      
      height: 100%;
      max-height: 100%;

      display: flex;
    }
      /* flex-direction: column; */
      
      div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_ItemIcon__1feJp {
        width: 50px;
        max-width: 50px;
        max-height: 50px;
        margin-right: 10px;
        margin-left: 10px;
        border: 2px solid var(--mainWhiteOrBlack);
        border-radius: 50%;
        color: var(--mainWhite);
      }
        
        div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_ItemIcon__1feJp.style_ItemIconSelected__2A00s {
          background-color: var(--mainWhite);
        }

        div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_ItemIcon__1feJp img.style_ItemImg__3A7lq {
          width: 50px;
          height: 50px;
          margin: 0px;
          padding: 0px;
        }

      div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_ItemName__57Cy6 {
        margin-top: 2px;
        width: 70px;
        /* height: 20px; */
        font-weight: 400;
        font-size: 13px;
        /* white-space: nowrap; */
        overflow: hidden;
        /* text-overflow: ellipsis; */
        color: var(--mainWhiteOrBlack);
      }

      div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_Subcats__385W8{
        /* border: 1px solid red; */
        display: flex;
        /* justify-content: center; */
        align-items: center;
      }

      div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_SubcatName__1q5iM{
        width: min-content;
        color: var(--mainWhiteOrBlack);
        font-size: 13px;
      }

      div.style_Container__3ttpU div.style_TopDiv__2UgMX div.style_Subcats__385W8 > *{
        margin-right: 12px;
        margin-left: 12px;
      }
}

div.style_Container__akcGf{
    align-self: flex-start;
    flex: 1 1;
    justify-self: stretch;
}
    /* justify-content: center; */
    div.style_Container__akcGf div.style_Cols_3__jDcpu {
        display: flex;
        flex-flow: row-reverse wrap;
        min-width: 90%;
        text-align: center;
        justify-content: space-between;
        height: 77vh;
    }
    /* height: 90vh; */
    div.style_Container__akcGf div.style_Cols_2__1F1Jm {
        display: flex;
        flex-flow: row-reverse wrap;
        min-width: 90%;
        text-align: center;
        justify-content: flex-end;
        height: 77vh;
    }
    div.style_Container__akcGf div.style_FullScreen__1dZxT {
        display: flex;
        flex-flow: row-reverse wrap;
        min-width: 90%;
        text-align: center;
        justify-content: flex-end;
        height: 77vh;
    }
.style_Container__3wFAx {
  /* display: flex;
  flex-flow: column nowrap;
  align-items: center; */
  max-height: 82vh;
  overflow: hidden;
}
.style_Header__1FEyG {
  /* border: 1px solid red; */
  text-align: center;
  color: var(--mainWhiteOrBlack);
  font-size: 2em;
  margin-bottom: 2vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.style_Header__1FEyG div{
    flex: 1 1;
  }

@media screen and (max-width: 600px) {
  div.style_container__2MvXC {
    /* width: 90vw; */
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
  }
}
  
.style_Container__9xmDF{
  /* border: 4px solid blue; */
  overflow: hidden;
  width: 100%;
  height: 85vh;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px;
  margin: 0 5px;
}
  /* height: 100%; */
  .style_Container__9xmDF .style_Spinner__2sfcK{
      /* border: 1px solid red; */
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60vh;
    }
  .style_Container__9xmDF .style_HistList__3YY9U {
      /* border: 5px solid pink; */
      z-index: 0;
    
      height: 85vh;
      overflow: auto;
      flex: 4 4;
      flex-flow: column nowrap;
      padding-top: 0;
      padding-bottom: 20vh;
      margin: 0;
    }
/* .TopDiv {
  /* border: 8px solid purple;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 5px;
  overflow: hidden;
  height: 100%;
} */

/* .contBasket {
  /* border: 6px solid orange; 
  flex: 2 2;
  /* width: 100%;
  height: 100%;
} */
/* 
body[dir="rtl"] .contBasket {
  margin-right: 10px;
  margin-left: 0;
} */



/* .Basket {
  /* border: 3px solid green; 
  display: flex;
  height: 85vh;
  flex-flow: column nowrap;
  background-color: var(--mainWhite);
  border-radius: var(--brdRadius);
  /* overflow: auto;

  position: relative;
} */
.style_closeBasket__2ryJr {
  width: max-content;
  height: max-content;
  position: absolute;
  top:0;
  left:0;
  padding-left: 10px;
  padding-top: 5px;
  cursor: pointer;
  font-size: 25px;
}

/* @media screen and (max-width: 850px) {
  .contBasket{
    /* flex: unset;
    width: 100%;
  }
} */

/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column nowrap;
    }
  } */
.style_Container__3qti6{
  /* border: 6px solid red; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-self: center; */
  /* height: 100%; */
  flex: 2 2;
  height: 100%;
  margin-inline-end: 10px;
  /* width: 100%; */
  background-color: var(--mainWhite);
  border-radius: var(--brdRadius);
}
  
  .style_Container__3qti6 .style_CancelXBtn__3ADIs {
    position: relative;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    align-self: flex-end;
    display: none;
  }
.style_TopDiv__B8OUR {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    height: 100%;
    margin-top: 15px;
    padding-bottom: 10px;
    color: var(--mainColor)
}

.style_OrderNumber__1_IkO{
  font-size: calc(.2vw + 14px);
  font-weight: 600; 
}

.style_List__bEO0G {
  /* border: 6px solid red; */
  overflow: auto;
  height: 100%;
  margin-top: 35px;
  padding: 0 15px 0 15px;
  flex: 4 1;
  
}

.style_ButtonWrapper__3-Jot{
  /* border: 6px solid blue; */
  height: 15%;
  display: flex;
  justify-content: center;
}
.style_button__bgAaB {
  cursor: pointer;
  align-self: center;
  width: 50%;
  /* margin: 30px 0 30px 0; */
  border-radius: 20px;
  font-size: calc(.2vw + 12px);

}

@media screen and (max-width: 850px) {
  .style_Container__3qti6{
    flex: 2 2 95%;
  }
    .style_Container__3qti6 .style_CancelXBtn__3ADIs {
      display: block;
    }
}

/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column nowrap;
    }
  } */

div.style_Container__27q9Q{
    display: flex;
    width: 100%;
    white-space: nowrap;
    padding: 5px 0 5px 0;
    border-bottom: 2px solid rgba(0,0,0,.2);
}

    /* border: 3px solid red; */

    div.style_Container__27q9Q div.style_RightDiv__32DW5 div.style_Image__2ZbqG{
            cursor: pointer;
            width: 100%;
            height: 100%;
        }

    div.style_Container__27q9Q div.style_RightDiv__32DW5 div.style_Image__2ZbqG img{
                max-width: calc(.2vw + 200px);
                max-height: calc(.2vw + 65px);
            }

    div.style_Container__27q9Q div.style_CenterDiv__2Q0BR{
        /* border: 3px solid blue; */
        width: 100%;
        cursor: default;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
    }

    div.style_Container__27q9Q div.style_CenterDiv__2Q0BR div.style_Name__2ecOg{
            font-size: calc(.2vw + 11px);
            font-weight: 600;
            color: var(--mainMainOrBlack);
        }

    div.style_Container__27q9Q div.style_CenterDiv__2Q0BR div.style_CenterBottomDiv__1oogW{
            font-size: calc(.2vw + 10px);
        }

    div.style_Container__27q9Q div.style_CenterDiv__2Q0BR div.style_CenterBottomDiv__1oogW div.style_ItemCost__nxuMr{
                font-weight: 600;
            }

    div.style_Container__27q9Q div.style_CenterDiv__2Q0BR div.style_CenterBottomDiv__1oogW div.style_Comment__2MSvl{
                overflow: hidden;
                text-overflow: ellipsis;
                text-decoration: underline;
                color: #999;
            }

    div.style_Container__27q9Q div.style_CenterDiv__2Q0BR > * + *{
        margin-top: 15px;
    }

    div.style_Container__27q9Q div.style_LeftDiv__3NYXf{
        /* border: 3px solid orange; */
        cursor: default;
        text-align: right;
        width: 40%;
    }

    div.style_Container__27q9Q div.style_LeftDiv__3NYXf div.style_Amount__1FiYC{
            color: #888;
            font-size: calc(.2vw + 10px);

        }

    div.style_Container__27q9Q div.style_LeftDiv__3NYXf div.style_TotalCost__1HukP{
            font-size: calc(.2vw + 13px);
            color: #000;
            font-weight: 600;
        }


div.style_Container__27q9Q > * + *{
    margin-right: 15px;
}
.style_container__2Be9r{
  align-self: center;
  width: 100%;
  /* height: 150px; */
  height: max-content;
  /* margin: 0 0 10px 10px;
  padding: 10px 0 10px 0; */
  border-radius: var(--brdRadius);
  background-color: var(--mainWhite);
}
.style_TopDiv__2k6f0 {
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-content: stretch;
    margin-bottom: 10px;

    min-height: 90px;
}
.style_info__o9KZu {
  cursor: pointer;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  flex: 5 1;
  margin-right: 5px;
}
.style_infoRow__mu5Om {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  /* justify-content: space-between; */
}
.style_col__1F6aS {
  /* border: 2px solid red; */
  min-width: 170px;

}
.style_col__1F6aS button {
    border-radius: var(--brdRadius);
    border: none;
    color: var(--mainWhite);
    font-size: 20px;
    background-color: var(--mainColor);
    cursor: pointer;
  }
.style_col_del__1FIi0 {
  /* border: 2px solid blue; */
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 170px;
}

.style_timerWrapper__3gx8m{
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 5px;
  color: var(--mainColor);

  position: relative;
}

.style_timerWrapper__3gx8m .style_inner__BnTYb{
    position: absolute;
    /* border: 2px solid red; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-size: 60px;
    border-radius: 100%;
  }

.style_timerWrapper__3gx8m .style_inner__BnTYb .style_time__1V7zK{
      font-size: 20px; 
      font-weight: 600;
      line-height: 20px;
    }

.style_timerWrapper__3gx8m .style_inner__BnTYb .style_unit__1Hsgc{
      font-size: 8px;
      font-weight: 600;
    }


.style_col_del__1FIi0 > * + * {
  margin-right: 10%;
}

.style_colDesc__1jAwX {
  /* border: 2px solid green; */
  font-size: 1.2em;
  font-weight: 600;


}
.style_colVal__ZPIMy {
  font-size: 1.2em;
  color: var(--mainMainOrBlack);
}
.style_image__27a20 {
  flex: 1 1;
  align-self: center;
}
.style_image__27a20 .style_orderApprove__LkVUl {
    margin: auto;
  }
.style_image__27a20 .style_orderApprove__LkVUl button {
      background-color: var(--mainColor);
      border: unset;
      color: var(--mainWhiteOrBlack);
      border-radius: 15px;
      padding: 5px;
      outline: none;
      cursor: pointer;
    }
.style_image__27a20 .style_orderApproved__124sQ {
    margin: auto;
  }
.style_image__27a20 .style_orderApproved__124sQ span {
      display: block;
      color: var(--mainMainOrBlack);
    }
.style_line__1xlPw {
  width: 100%;
}
/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column nowrap;
    }
  } */
@media (max-width: 600px) {
      /* display: flex;
      flex-flow: column nowrap; */
        /* border: 3px solid blue; */
        div.style_container__2Be9r .style_TopDiv__2k6f0 div.style_info__o9KZu div.style_infoRow__mu5Om{
          /* border: 1px solid red; */
          display: flex;
          justify-content: space-between;
          flex-flow: unset;
        }

          div.style_container__2Be9r .style_TopDiv__2k6f0 div.style_info__o9KZu div.style_infoRow__mu5Om div.style_col__1F6aS{
            /* border: 2px solid green; */
            min-width: unset;
            margin: auto;
          }
            
            div.style_container__2Be9r .style_TopDiv__2k6f0 div.style_info__o9KZu div.style_infoRow__mu5Om div.style_col__1F6aS div.style_colDesc__1jAwX{
              font-size: .9em;
              font-weight: 600;
            }
            div.style_container__2Be9r .style_TopDiv__2k6f0 div.style_info__o9KZu div.style_infoRow__mu5Om div.style_col__1F6aS div.style_colVal__ZPIMy{
              font-size: .8em;
            }

          div.style_container__2Be9r .style_TopDiv__2k6f0 div.style_info__o9KZu div.style_infoRow__mu5Om div.style_col_del__1FIi0{
            /* border: 3px solid pink; */
            margin: 0;
            width: 0;
          }
    .style_orderApproved__124sQ span {
      font-size: 11px;
    }
}

div.style_Container__29x_B{
    text-align: center;
    margin: 0;
    padding: 0;
    position: relative;
}

    div.style_Container__29x_B div.style_CancleXBtn__15klH{
        position: absolute;
        left: 0;
        text-align: left;
        color: #808080;
    }

    div.style_Container__29x_B div.style_CancleXBtn__15klH span{
            font-size: 22px;
        }

    div.style_Container__29x_B div.style_CancleXBtn__15klH span:hover{
            cursor: pointer;
        }

    div.style_Container__29x_B div.style_TextContnet__XLxlj{
        margin-top: 5px;
    }

    div.style_Container__29x_B div.style_TextContnet__XLxlj div.style_SadImg__o6YSg{

        }

    div.style_Container__29x_B div.style_TextContnet__XLxlj div.style_Title__qBE41 span{
                font-weight: 550;
                font-size: 23px;
            }

    div.style_Container__29x_B div.style_TextContnet__XLxlj div.style_RowsText__2TSvo{
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            font-size: 20px;
        }

    div.style_Container__29x_B .style_BackBtn__3HERm{
        display: flex;
        justify-content: center;
        margin-top: 10px;
        color: var(--mainWhiteOrBlack);
    }

    div.style_Container__29x_B .style_BackBtn__3HERm span{
            background-color: var(--mainColor);
            border-radius: 20px;
            width: 55%;
            font-size: 20px;
            padding: 5px;
        }

    div.style_Container__29x_B .style_BackBtn__3HERm span:hover{
            cursor: pointer;
        }

    div.style_Container__29x_B .style_ContinueBtn__25DBO{
        display: flex;
        justify-content: center;
        margin-top: 10px;
        color: var(--mainMainOrBlack);
    }

    div.style_Container__29x_B .style_ContinueBtn__25DBO span{
            border: 1px solid var(--mainMainOrBlack);
            border-radius: 20px;
            width: 55%;
            font-size: 20px;
            padding: 5px;
        }

    div.style_Container__29x_B .style_ContinueBtn__25DBO span:hover{
            cursor: pointer;
        }
.style_container__DOZ0J{
  /* align-self: center;
  justify-self: center; */
  /* margin: auto;
  margin-top: 5%; */
  flex: 3 0 100%;
}
  /* width: 100vw; */
  .style_container__DOZ0J .style_TopDiv__1Sb8U {
    text-align: center;
    /* display: flex; */
    /* flex-flow: column nowrap; */
    /* justify-content: space-around; */
    margin: 5px;  
  }
  .style_container__DOZ0J .style_TopDiv__1Sb8U .style_Desc__1JiyG {
      color: var(--mainWhite);
      opacity: .9;
      font-size: 1.5em;
      margin-bottom: 20px;
      /* display: flex; */
      /* flex-flow: column nowrap; */
    }
  .style_container__DOZ0J .style_TopDiv__1Sb8U button{
      opacity: .95;
      border-radius: 15px;
      padding: 10px 0 10px 0;
      color: var(--mainColor);
      background-color: transparent;
      cursor: pointer;
      border: 2px solid var(--mainCol1);
    }
  .style_container__DOZ0J .style_TopDiv__1Sb8U button div{
        padding: 5px 15px 5px 15px;
        font-size: 0.4em;
        margin-bottom: 10px;
      }
  .style_container__DOZ0J .style_TopDiv__1Sb8U button:last-child{ 
      color: var(--mainWhite);
      font-size: 120px;
    }

/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column-reverse wrap;
    }
  }
   */
.style_ModalContainer__2IbKk {
    position: fixed;
    top: 0;
    left: 0;
    /* z-index: 9999; */
    width: 100%;
    height: 100%;
    display: flex;    
}
    
    /* background-color: rgba(0, 0, 0, 0.5); */
    
    .style_ModalContainer__2IbKk .style_CancelXBtn__3OtBP {
        position: absolute;
        top: 20px;
        right: 60px;
        width: 30px;
        height: 30px;
        background-color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        z-index: 100;
    }
    
    .style_ModalContainer__2IbKk .style_Content__24JP5{
        margin-bottom: 0;
        flex: 1 1;
    }
    
    .style_ModalContainer__2IbKk .style_Content__24JP5 .style_CourierInfo__3FzQh{
            /* background-color: var(--mainColor); */
            position: absolute;
            top: 5vh;
            text-align: center;
            width: 40%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 100;
            /* padding-top: 1px; */
            /* display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; */
    
        }
    
    .style_ModalContainer__2IbKk .style_Content__24JP5 .style_ProgressBar__1f2El {
            position: absolute;
            bottom: 0;
            width: max(40%, 320px);
            left: 50%;
            transform: translateX(-50%);
            background-color: var(--mainWhite);
            border-radius: 30px;
            z-index: 100;
            box-shadow: 5px 5px 5px 0px #CECCC6;
           
        }
    
    .style_ModalContainer__2IbKk .style_Content__24JP5 .style_ProgressBar__1f2El h1{
                margin-inline-start: 15%;
                font-size: 'max(2vw, 18px)';
            }
    
    /* .Milestones:last-child:before {
                    content: 'W';
                } */
    
    .style_ModalContainer__2IbKk .style_Content__24JP5 .style_ProgressBar__1f2El .style_Milestones__266LY {
                width: max(70%, 250px);
                margin-left: auto;
                margin-right: auto;
            }
    
    .style_ModalContainer__2IbKk .style_Content__24JP5 .style_ProgressBar__1f2El .style_Milestones__266LY .style_Milestone__1zJtK {
                    width: 100%;
                    /* overflow: hidden; */
                    white-space: nowrap;   
                }
    
    .style_ModalContainer__2IbKk .style_Content__24JP5 .style_ProgressBar__1f2El .style_Milestones__266LY .style_Milestone__1zJtK .style_Checkbox__3-qLW {
                        font-size: 25px;
                        margin-inline-end: 10px;
                        margin-inlien-start: 1px;
                        position: relative;
                        top: 10px;
                    }
    
    .style_ModalContainer__2IbKk .style_Content__24JP5 .style_ProgressBar__1f2El .style_Milestones__266LY .style_Milestone__1zJtK:not(:last-child)::before {
                    content: '|';
                    position: relative;
                    opacity: 0.4;   
                    top: 24px;
                    right: 14px;
                }
    
    .style_ModalContainer__2IbKk .style_Content__24JP5 .style_ProgressBar__1f2El .style_Milestones__266LY .style_Milestone__1zJtK:last-child {
                    margin: 0 3px 0 0;
                }
    
    .style_ModalContainer__2IbKk .style_Content__24JP5 .style_ProgressBar__1f2El .style_Milestones__266LY .style_Pending__2mfy7 {
                    color: grey;
                }
    
    .style_ModalContainer__2IbKk .style_Content__24JP5 .style_ProgressBar__1f2El .style_Milestones__266LY .style_Active__3WA3L {
                    color: var(--mainColor);
                }
    
    .style_ModalContainer__2IbKk .style_Content__24JP5 .style_ProgressBar__1f2El .style_Milestones__266LY .style_Completed__3zzgn {
                    color: black;
                }

@media (max-width: 850px) {
            .style_ModalContainer__2IbKk .style_Content__24JP5 .style_CourierInfo__3FzQh {
                width: 80%;
            }
            .style_ModalContainer__2IbKk .style_Content__24JP5 .style_ProgressBar__1f2El {
                width: 80%;
            }
                .style_ModalContainer__2IbKk .style_Content__24JP5 .style_ProgressBar__1f2El .style_Milestones__266LY {
                    width: 93%;
                }
}

div.style_container__23Tdb {
  align-self: flex-start;
  flex: 1 1;
  display: flex;
  /* flex-flow: column; */
  justify-self: stretch;
  height: 70vh;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

  div.style_container__23Tdb div.style_Header__1FjtR {
    font-size: 60px;
    font-weight: 800;
    color: var(--mainWhiteOrBlack);
    padding-top: 30px;
    padding-bottom: 20px;
  }

  div.style_container__23Tdb div.style_About__wuDPT {
    max-width: 1000px;
    background-color: var(--mainWhite);
    white-space: pre-line;
    text-align: center;
    padding: 30px;
    border-radius: var(--brdRadius);
    font-size: 20px;
    overflow: auto;
  }

  div.style_container__23Tdb div.style_About__wuDPT span.style_Bigger__svhzp {
      font-size: 1.5rem;
    }

@media (max-width: 850px){
    div.style_container__23Tdb div.style_Header__1FjtR {
      font-size: 40px;
      font-weight: 600;
      padding-top: 0px;
    }

    div.style_container__23Tdb div.style_About__wuDPT{
      width: 100vw;
      font-size: 17px;
      word-wrap: break-word;
    }
}

/* @media screen and (max-width: 600px) {
  div.About {
    width: 90vw;
    display: flex;
    flex-flow: column-reverse wrap;
  }
} */
div.style_container__1W2j_ {
  align-self: flex-start;
  flex: 1 1;
  display: flex;
  /* flex-flow: column; */
  justify-self: stretch;
  height: 70vh;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

  div.style_container__1W2j_ div.style_Header__2wtX7 {
    font-size: 60px;
    font-weight: 800;
    color: var(--mainWhiteOrBlack);
    padding-top: 30px;
    padding-bottom: 20px;
  }

  div.style_container__1W2j_ div.style_About__1E7sx {
    max-width: 1000px;
    background-color: var(--mainWhite);
    white-space: pre-line;
    text-align: center;
    padding: 30px;
    border-radius: var(--brdRadius);
    font-size: 20px;
    overflow: auto;
  }

@media (max-width: 850px){
    div.style_container__1W2j_ div.style_Header__2wtX7 {
      font-size: 40px;
      font-weight: 600;
      padding-top: 0px;
    }

    div.style_container__1W2j_ div.style_About__1E7sx{
      width: 100vw;
      font-size: 17px;
      word-wrap: break-word;
    }
}

/* @media screen and (max-width: 600px) {
  div.About {
    width: 90vw;
    display: flex;
    flex-flow: column-reverse wrap;
  }
} */
div.style_container__kmw_c {
  display: flex;
    height: calc((var(--vh, 1vh) * 100) - 100px - var(--AppFooter));
    width: 100%;
    margin: auto;
    flex-direction: column;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    overflow: auto;
}

  div.style_container__kmw_c div.style_Header__Qn_w2 {
    font-size: 28px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--mainWhiteOrBlack);
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    width: 50%;
    background-color: var(--mainColor);
  }

  div.style_container__kmw_c .style_FormContainer__1nwqk{
    margin: 10px 0 0;
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 18px;
    background-color: #fff;
    border-radius: 12px;
    padding: 40px 2%;
  }

  div.style_container__kmw_c .style_FormContainer__1nwqk .style_TermsLine__emipM {
      width: 100%;
      padding: 0 1%;
      text-align: center;
    }

  div.style_container__kmw_c .style_FormContainer__1nwqk .style_TermsLine__emipM div.style_CheckTerm__uDPmZ{
        width: 100%;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

  div.style_container__kmw_c .style_FormContainer__1nwqk .style_TermsLine__emipM div.style_CheckTerm__uDPmZ input{
            width: 20px;
            height: 30px;
        }

  div.style_container__kmw_c .style_FormContainer__1nwqk .style_TermsLine__emipM div.style_CheckTerm__uDPmZ label{
            width: 20rem;
            font-size: 17px;
           
            color: var(--text-error);
        }

  div.style_container__kmw_c .style_FormContainer__1nwqk .style_TermsLine__emipM div.style_CheckTerm__uDPmZ label span{
                font-weight: 900;
                text-decoration: underline;
                color: var(--link-error);
                text-transform: lowercase;
            }

  div.style_container__kmw_c .style_FormContainer__1nwqk .style_TermsLine__emipM div.style_CheckTerm__uDPmZ label span:hover{
                cursor: pointer;
            }

  div.style_container__kmw_c .style_FormContainer__1nwqk .style_Form__3s2mI {
      /* border: 1px solid red; */
      width: 100%;
      padding: 0 28%;
      z-index: 1;
    }

  div.style_container__kmw_c .style_FormContainer__1nwqk .style_Form__3s2mI div.style_FormLine__2tf23 {
        width: 100%;
        /* body[dir="rtl"] input.FormInput {
          text-align: left;
        } */
        
      }

  div.style_container__kmw_c .style_FormContainer__1nwqk .style_Form__3s2mI div.style_FormLine__2tf23 div.style_FormLineOne__1JznW {
          width: 100%;
          padding: 3px;
          
        }

  div.style_container__kmw_c .style_FormContainer__1nwqk .style_Form__3s2mI div.style_FormLine__2tf23 div.style_FormLineTwo__1Ffum {
          width: 50%;
          padding: 3px;
          display: inline-block;
        }

  div.style_container__kmw_c .style_FormContainer__1nwqk .style_Form__3s2mI div.style_FormLine__2tf23 .style_FormInput__2mue7 {
          display: inline-block;
          width: 100%;
          height: 42px;
          outline: none;
          border-radius: 6px;
          resize: none;
          overflow: auto;
          font-family: 'Heebo', sans-serif;
          padding: 2px 5px 2px 5px;
          box-shadow: 1px 1px 2px #3B3B3B7A;
          font-size: 22px;
          border: 1px solid rgb(255, 255, 255);
        }

  div.style_container__kmw_c .style_FormContainer__1nwqk .style_Form__3s2mI div.style_FormLine__2tf23 .style_FormInput__2mue7.style_FormInputWrong__2rOmL {
            border: 1px solid #eb0028;
          }

  div.style_container__kmw_c div.style_RegisterButton__3oVYG {
    margin-bottom: 15px;
    font-size: 30px;
    width: 570px;
    margin-top: 20px;
    text-align: center;
    padding: 8px;
    color: var(--mainWhiteOrBlack);
    border-radius: 50px;
    cursor: pointer;
    background-color: var(--mainColor);
  }

  div.style_container__kmw_c div.style_RegisterButton__3oVYG:hover{
    opacity: .9;
  }

  div.style_container__kmw_c div.style_ModalContainer__mI6RG{
    display: flex;
    justify-content: center;
    align-items: center;
  }

@media (max-width: 850px){
  div.style_container__kmw_c {
    height: calc((var(--vh, 1vh) * 100) - 100px);
  }

    div.style_container__kmw_c div.style_Header__Qn_w2 {
      font-size: 20px;
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 10px;
      width: auto;
      max-width: 90%;
    }
    div.style_container__kmw_c .style_FormContainer__1nwqk::before {
      top: -10px;
      right: -10px;
      left: -10px;
      bottom: -10px;
    }
    div.style_container__kmw_c .style_FormContainer__1nwqk{
      width: 100%;
    }
      div.style_container__kmw_c .style_FormContainer__1nwqk div.style_Form__3s2mI {
        width: 100%;
        z-index: 0;
        padding: 0 5%;
      }

        div.style_container__kmw_c .style_FormContainer__1nwqk div.style_Form__3s2mI div.style_FormLine__2tf23 {
          width: 100%;
          /* body[dir="rtl"] input.FormInput {
            text-align: left;
          } */
        }
    
          div.style_container__kmw_c .style_FormContainer__1nwqk div.style_Form__3s2mI div.style_FormLine__2tf23 div.style_FormLineOne__1JznW {
          }
    
          div.style_container__kmw_c .style_FormContainer__1nwqk div.style_Form__3s2mI div.style_FormLine__2tf23 div.style_FormLineTwo__1Ffum {
          }

          div.style_container__kmw_c .style_FormContainer__1nwqk div.style_Form__3s2mI div.style_FormLine__2tf23 .style_FormInput__2mue7 {
            font-size: 20px;
            padding: 5px;
            padding-left: 10px;
            padding-right: 10px;
          }
    div.style_container__kmw_c div.style_RegisterButton__3oVYG {
      z-index: 0;
      font-size: 30px;
      width: 80vw;
      margin-top: 40px;
      padding: 4px;
    }
}
div.style_ModalContainer__3AQ-I form{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
        /* gap: 30px; */
        div.style_ModalContainer__3AQ-I form div.style_ModalTitle__1urpn{
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 30px;
        }
        div.style_ModalContainer__3AQ-I form div.style_ModalTitle__1urpn div.style_WrongCodeText__3Gp7V{
                color: #FF0000;
                font-size: 15px;
                font-weight: 400;
            }
        div.style_ModalContainer__3AQ-I form div.style_ModalTitle__1urpn div.style_BlockedUserText__2u5uE{
                color: #FF0000;
            }
        div.style_ModalContainer__3AQ-I form div.style_ModalInputs__TPOHW{
            margin-bottom: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        /* gap: 0.6vw; */
        div.style_ModalContainer__3AQ-I form div.style_ModalInputs__TPOHW input{
                margin: 7px;
            }
        div.style_ModalContainer__3AQ-I form div.style_ModalInputs__TPOHW div.style_NewCodeText__30LZL{
                color: #FF0000;
                font-size: 17px;
            }
        div.style_ModalContainer__3AQ-I form div.style_ModalInputs__TPOHW div.style_NewCodeText__30LZL span:nth-child(1){
                    font-weight: 300;
                }
        div.style_ModalContainer__3AQ-I form div.style_ModalInputs__TPOHW div.style_NewCodeText__30LZL span:nth-child(2){
                    font-weight: 600;
                    text-decoration: underline;
                }
        div.style_ModalContainer__3AQ-I form div.style_ModalInputs__TPOHW div.style_NewCodeText__30LZL span:nth-child(1):hover{
                    cursor: context-menu;
                }
        div.style_ModalContainer__3AQ-I form div.style_ModalInputs__TPOHW div.style_NewCodeText__30LZL span:nth-child(2):hover{
                    cursor: pointer;
                    color: #ff5c5c;
                }
        div.style_ModalContainer__3AQ-I form div.style_ModalBtns__2kkQU{
            display: flex;
            /* justify-content: space-around; */
            align-items: center;
            margin-top: 20px;
    
            /* button:nth-child(2):hover{
                background-color: #ff2323;
                color: #FFFF;
                cursor: pointer;
            }
    
            button:nth-child(1):hover{
                background-color: #cacaca;
                color: #ffff;
                cursor: pointer;
            } */
        }
        /* gap: 7vw; */
        div.style_ModalContainer__3AQ-I form div.style_ModalBtns__2kkQU button{
                margin: 0 15px 0 15px;
                font-family: Heebo;
                outline: gray;
                border: none;
                border-radius: 25px;
                width: 120px;
                height: 50px;
                font-size: 20px;
                /* color: #ff0000; */
                background-color: #FFFF;
                cursor: pointer;
                
            }
        div.style_ModalContainer__3AQ-I form div.style_ModalBtns__2kkQU button:nth-child(2){
                color: var(--mainMainOrBlack);
                border: 1px solid var(--mainMainOrBlack);
            }
        div.style_ModalContainer__3AQ-I form div.style_ModalBtns__2kkQU button:nth-child(1){
                border: 1px solid #cacaca;
                color: #ababab;
            }
div.style_Inputs__2rVgM{
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 0.6vw; */
    direction: ltr;
}
    
    div.style_Inputs__2rVgM input{
        text-align: center;
        /* width: 3.5vw; */
        /* margin-right: 0.6vw; */
        outline: none;
        border: none;
        border-bottom: 2px solid #cacaca;
        font-size: 27px;
        font-weight: 500;
        background-color: unset;
        /* color: rgb(0,0,0,0.68); */
    }

div.style_ModalContainer__3b7_P{
    width: 100%;
}

    div.style_ModalContainer__3b7_P div.style_CloseBtn__3GdwY{
        text-align: right;
        color: #808080;
        font-weight: 700;
        font-size:23px;
    }

    div.style_ModalContainer__3b7_P div.style_CloseBtn__3GdwY span:hover{
            cursor: pointer;
        }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: #FFF;
        padding: .2rem;
    }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT div.style_BodyContent__3sn8K{
            text-align: center;
            color: #000;
            font-size: 24px;
        }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT div.style_BodyContent__3sn8K :nth-child(3){
                font-weight: 600;
            }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT div.style_BodyContent__3sn8K :nth-child(4) span{
                    font-weight: 600;
                    text-decoration: underline;
                    cursor: pointer;
                }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT div.style_BodyContent__3sn8K :nth-child(4) span:hover{
                    opacity: .6;
                }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT div.style_FootContent__S4jdO{
            text-align: center;
            font-size: 15px;
        }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT div.style_FootContent__S4jdO :nth-child(1){
                text-decoration: underline;
            }

    div.style_ModalContainer__3b7_P div.style_Content__3mpjT p{
            margin: 0;
        }

div.style_Container__tKJ7t {
    position: relative;
    width: 100%;
}
    
    div.style_Container__tKJ7t .style_FlagPlaceholder__4_KaH {
        position: absolute;
        left: 10px;
        top: 50%;
        /* width: 30px;
        height: 30px;
        border-radius: 10px; */
        overflow: hidden;
        transform: translateY(-50%);
      }
    
    body[dir="rtl"] .style_FlagPlaceholder__4_KaH div.style_Container__tKJ7t{
       text-align: right;
    }
div.style_ModalContainer__32cpL div.style_CloseBtn__36Mkb{
        text-align: left;
        color: #808080;
        font-weight: 700;
        font-size:23px;
    }
        
        div.style_ModalContainer__32cpL div.style_CloseBtn__36Mkb span:hover{
            cursor: pointer;
        }
    div.style_ModalContainer__32cpL div.style_Body__2o2Yo{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* gap: 30px; */
    }
    /* margin-top: 15px; */
    div.style_ModalContainer__32cpL div.style_Body__2o2Yo div.style_ModalTitle__199qL{
            margin-top: 30px;
            text-align: center;
            color: #000;
            font-size: 25px;
            font-weight: 500;
        }
    div.style_ModalContainer__32cpL div.style_ContinueBtn__pS7Yx{
        display: flex;
        justify-content: center;
        margin-top: 35px;
        color: var(--mainMainOrBlack);
        text-align: center;
    }
    div.style_ModalContainer__32cpL div.style_ContinueBtn__pS7Yx span{
            border: 1px solid var(--mainMainOrBlack);
            border-radius: 20px;
            width: 55%;
        }
    div.style_ModalContainer__32cpL div.style_ContinueBtn__pS7Yx span:hover{
            cursor: pointer;
        }

div.style_ModalContainer__DHgMb{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* gap: 30px; */
    margin-top: 15px;
}
    
    div.style_ModalContainer__DHgMb .style_ModalTitle__y2J_E{
        color: #000000;
        font-size: 50px;
        font-weight: 600;
    }
    
    div.style_ModalContainer__DHgMb .style_ModalTitle__y2J_E::first-letter{
        text-transform: uppercase;
    }
    
    div.style_ModalContainer__DHgMb div.style_ModalContent__1Id-a{
        margin: 50px;
        margin-top: 0;
        font-weight: 500;
    }
    
    div.style_ModalContainer__DHgMb div.style_ModalButton__1j5h3{
        height: 40px;
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #000000;
        border-radius: 20px;
        margin-bottom: 20px;
        font-weight: 550;
        font-size: 20px;
    }
    
    div.style_ModalContainer__DHgMb div.style_ModalButton__1j5h3 span{
            margin: 10px;
        }
    
    div.style_ModalContainer__DHgMb div.style_ModalButton__1j5h3:hover{
        background-color: #000000;
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
        cursor: pointer;
    }

div.style_ModalContainer__DHgMb > *{
    margin-bottom: 30px; 
}

.style_container__1927f{
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  height: 70vh;
}

  .style_container__1927f div.style_Header__1uLJg {
    font-size: 60px;
    font-weight: 800;
    color: var(--mainWhiteOrBlack);
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .style_container__1927f div.style_Contacts__1UaC7 {
    width: 100vw;
    max-width: 800px;
    background-color: var(--mainWhite);
    padding: 20px 150px 20px 150px;
    border-radius: var(--brdRadius);
    font-size: 20px;
    overflow: auto;
  }

  .style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsName__2tWME {
      font-size: 30px;
      color: var(--mainMainOrBlack);
    }

  .style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsPhone__WfnJR {
      font-size: 30px;
    }

  .style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsPhone__WfnJR a {
        color: inherit;
        text-decoration: inherit;
      }

  .style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsDesc__3icjk {
      font-size: 30px;
    }

  .style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsEmail__3oK3o {
      font-size: 30px;
    }

  .style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsEmail__3oK3o a {
        color: inherit;
        text-decoration: inherit;
      }

.style_Contacts__1UaC7> * {
  margin: 3px;
  color: var(--black);
  text-align: center;
}


@media (max-width: 850px){
    div.style_container__1927f div.style_Header__1uLJg {
      font-size: 40px;
      font-weight: 600;
      padding-top: 0px;
    }

    div.style_container__1927f div.style_Contacts__1UaC7 {
      width: 100vw;
      background-color: var(--mainWhite);
      /* text-align:justify; */
      /* align-content: center; */
      padding: 10px 25px 10px 25px;
      font-size: 14px;
  
      overflow: auto;
    }
  
      div.style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsName__2tWME {
        font-size: 20px;
        font-weight: 500;
      }
  
      div.style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsPhone__WfnJR {
        font-size: 18px;
      }
  
        div.style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsPhone__WfnJR a {
          color: inherit;
          text-decoration: inherit;
        }
  
      div.style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsDesc__3icjk {
        font-size: 20px;
      }
  
      div.style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsEmail__3oK3o {
        font-size: 25px;
      }
  
        div.style_container__1927f div.style_Contacts__1UaC7 div.style_ContactsEmail__3oK3o a {
          color: inherit;
          text-decoration: inherit;
        }

}

/* @media screen and (max-width: 600px) {
  div.Contacts {
    width: 90vw;
    display: flex;
    flex-flow: column-reverse wrap;
  }
} */
.style_container__2mg-e{
  padding: 0;
  margin: 0;

  align-self: center;
}

  .style_container__2mg-e .style_TopDiv__gXspK {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    margin: 15px;
  }

  .style_container__2mg-e .style_TopDiv__gXspK .style_day__24X6r {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      font-size: 24px;
    }

  .style_container__2mg-e .style_TopDiv__gXspK .style_day__24X6r .style_dayName__1eh0_ {
        justify-self: flex-start;
      }

  .style_container__2mg-e .style_TopDiv__gXspK .style_day__24X6r .style_dayHours__xdYWI {
        justify-self: flex-end;
      }


@media (max-width: 850px){
      .style_container__2mg-e .style_TopDiv__gXspK .style_day__24X6r {
        font-size: 18px;
      }
}
/* @media screen and (max-width: 600px) {
    .TopDiv {
      display: flex;
      flex-flow: column-reverse wrap;
    }
  } */

div.style_Container__2xsja {
    /* border: 1px solid red; */
    /* max-height: 100%; */
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    margin-bottom: 5rem;
    height: 83vh;
}  


    div.style_Container__2xsja .style_WhiteScreen__1ML7q {
      position: absolute;
      inset: 50% auto auto 50%;
      border: 1px solid rgb(204, 204, 204);
      background: rgba(255, 255, 255, 0.5);
      overflow: auto;
      border-radius: 4px;
      outline: none;
      padding: 20px;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      z-index: 1;
    }  


    div.style_Container__2xsja div.style_PriceInfo__1lkgm{
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: var(--mainWhiteOrBlack);
      border-radius: 10px;
      padding: 15px;
      text-align: center;
      width: 50%;
      background-color: var(--mainColor);
    }  


    div.style_Container__2xsja div.style_PriceInfo__1lkgm div.style_PriceTitle__3u5qq{
        font-size: 28px;
        font-weight: 500;
      }  


    div.style_Container__2xsja div.style_PriceInfo__1lkgm div.style_PriceDescription__36UdW{
        font-size: 15px;
      }  


    div.style_Container__2xsja .style_PriceInfoBox__15IcW {
      color: #FFFFFF;
      font: normal normal bold 66px/90px Heebo;
      margin-bottom: 0;
    }
  
  /* @media screen and (max-width: 600px) {
      div.PPolicy {
        width: 90vw;
        display: flex;
        flex-flow: column-reverse wrap;
      }
  } */
  
  @media (max-width: 850px){
    div.style_Container__2xsja {
      margin-bottom: 0rem;
      height: calc((var(--vh, 1vh) * 100) - 100px);
      padding-bottom: 0;
    }
      div.style_Container__2xsja .style_PriceInfoBox__15IcW  {
        font: normal normal bold 32px / 40px Heebo;
        height: var(--PriceInfoHeight);
        align-items: center;
        display: flex;
      }
      div.style_Container__2xsja div.style_PriceInfo__1lkgm{
        width: 96%;
      }
        div.style_Container__2xsja div.style_PriceInfo__1lkgm div.style_PriceTitle__3u5qq{
          font-size: 20px;
          font-weight: 500;
        }
  
        div.style_Container__2xsja div.style_PriceInfo__1lkgm div.style_PriceDescription__36UdW{
          font-size: 10px;
        }
  }
div.style_ModalContainer__31H-C{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 43vh;
    position: relative;
}
    
    div.style_ModalContainer__31H-C div.style_SpinnerIcon__1vKjX{
        position: absolute;
        left: 48%;
    }
    
    div.style_ModalContainer__31H-C div.style_Content__3xSGs{
        margin-top: 163px;
    }
    
    div.style_ModalContainer__31H-C div.style_Content__3xSGs span{
            font-weight: 500;
        }
.style_container__3wrIo{
  min-height: 80vh;
  display: flex;
  justify-content: space-around;
}
.style_TopDiv__JSB3Q {
    text-align: center;
    direction: ltr !important;
    font-size: 2em;
    color: white;
    background-color: red;
    align-self: center;
    padding: 15px;
    border-radius: var(--brdRadius);
}

@media screen and (max-width: 600px) {
    .style_TopDiv__JSB3Q {
      display: flex;
      flex-flow: column-reverse wrap;
    }
  }
  
div.style_ModalContainer__3Goeh{
    /* margin: 0;
    padding: 0;
    background: url(../../../assets/welcome_bg.png) no-repeat;
    background-size: 100%;
    background-position: bottom;
    background-color: #FFFF;
    min-height: 100%; */

    margin-top: 25px;
    height: 240px;
    display: block;
}
    /* gap: 18px; */
    div.style_ModalContainer__3Goeh h3{
        margin: 60px auto 0 auto ;
        /* margin-left: auto;
        margin-right: auto; */
        text-align: center;
        width: 60%;
        font-size: 23px;
        overflow: none;
    }
    /* flex-direction: column;
    justify-content: space-evenly;
    align-items: center; */
    div.style_ModalContainer__3Goeh button{
        position: absolute;
        bottom: 5px;
        left: 0;
        right: 0;
        margin: 0 auto 15px auto;
        font-family: Heebo;
        outline: none;
        width: 185px;
        height: 45px;
        border-radius: 25px;
        font-size: 18px;
        align-text: center;
        font-weight: 600;
        background-color: unset;
        color: var(--mainMainOrBlack);
        border: 1px solid var(--mainMainOrBlack);
        cursor: pointer;
    }
    div.style_ModalContainer__3Goeh h3{
        text-overflow: ellipsis;
    }
div.style_cancleBtn__3fy8i{
    color: #000;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    left: 0px;
    top: 20px;
    background-color: var(--white);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 55px;
}

div.style_welcomeImageDiv__ohC_8 {
    width: 100%;
    height: 100%;
}

img.style_welcomeImage__1earO {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

div.style_ModalContainer__11JDS{
    /* background: var(--bg-modal-image);
    background-size: '100%';
    background-position: 'top';
    background-color: '#FFFF';
    maxHeight: '100%'; */
    /* height: 273px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    /* gap: 18px; */
    div.style_ModalContainer__11JDS h3{
        text-align: center;
        width: 80%;
        font-size: 23px;
    }
    div.style_ModalContainer__11JDS h4{
        width: 80%;
    }
    div.style_ModalContainer__11JDS button{
        margin-top: 10px;
        font-family: Heebo;
        outline: none;
        width: 185px;
        height: 35px;
        border-radius: 25px;
        font-size: 18px;
        align-text: center;
        font-weight: 600;
        background-color: unset;
        cursor: pointer;
        color: var(--mainMainOrBlack);
        border: 1px solid var(--mainMainOrBlack);
    }
    div.style_ModalContainer__11JDS h3{
        text-overflow: ellipsis;
    }
div.style_Container__2z5Gz{
    /* border: 4px solid blue; */
    width: 100%;
    height: 100%;
    padding-right: 2%;
    padding-left: 2%;
    background-color: #000;

    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

    div.style_Container__2z5Gz .style_MenuBtns__1Q9gp{
        /* border: 1px solid red; */
        height: 90%;
        overflow: hidden;
        text-align: center;
    }

    div.style_Container__2z5Gz .style_MenuBtns__1Q9gp .style_menubtn__nWDY5 {
            line-height: 250%;
            display: inline;
            padding: 10px;
            background-color: transparent;
            border: 0px;
            color: var(--mainWhite);
            font-size: 2.2vh;
            font-weight: 200;
            cursor: pointer;
        }

    div.style_Container__2z5Gz .style_MenuBtns__1Q9gp .style_menubtn__nWDY5.style_menubtnActive__3Awcq {
                font-weight: 400;
                -webkit-text-decoration: underline var(--mainWhite);
                        text-decoration: underline var(--mainWhite);
            }

    div.style_Container__2z5Gz div.style_BigappsLogo__2oNfJ{
        /* border: 1px solid red; */
        height: 71%;
        width: max-content;
        cursor: pointer;
    }

    div.style_Container__2z5Gz div.style_BigappsLogo__2oNfJ *:hover{
            opacity: .9;
        }

    div.style_Container__2z5Gz div.style_MobileBtns__2gouv{
        /* border: 2px solid red; */
        /* width: max-content; */
        display: flex;
        align-items: center;
    }

    div.style_Container__2z5Gz div.style_MobileBtns__2gouv img {
            max-height: 65px;
        }

    div.style_Container__2z5Gz div.style_MobileBtns__2gouv *{
            cursor: pointer;
            padding: 10px;
            width: auto;
        }

    div.style_Container__2z5Gz div.style_MobileBtns__2gouv *:hover{
            opacity: .8;
        }

div.style_VersionDiv__22lDg {
    color: var(--mainWhite);
    font-size: 10px;
    position: fixed;
}

body[dir="rtl"] div.style_VersionDiv__22lDg {
    left: 1px;
}

body[dir="ltr"] div.style_VersionDiv__22lDg {
    right: 1px;
}
div.style_cancleBtn__2BgEK{
    color: #000;
    font-size: 30px;
    cursor: pointer;
    position: absolute;
    left: 0px;
    top: 20px;
    background-color: var(--white);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 55px;
}

div.style_welcomeImageDiv__1ZurW {
    width: 100%;
    height: 100%;
}

img.style_welcomeImage__34RlW {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

* {
  /* direction: rtl; */
  box-sizing: border-box;
}

:root {
  --mainColor: gray;
  --mainColor065: gray;
  --mainWhite: white;
  --mainBg: white;
  --thinScrBg: gray;
  --brdRadius: 15px;
  --mainCol1: #F1A103;
  --blue: #1e90ff;
  --white: #ffffff;
  --black: black;
  --mainWhiteOrBlack: white;
  --mainMainOrBlack: black;
}

::-webkit-scrollbar {
  width: 2px;
}
/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */
::-webkit-scrollbar-thumb {
  background: var(--mainColor);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--mainWhite);
}

img {
  max-width: 100%;
  max-height: 100%;
}

.ReactModalPortal.gray_images {
  filter: none;
}

body.gray_images {
  overflow: hidden;
}
